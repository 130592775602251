import React, { useState } from 'react';
import { useAppContext } from '../../AppContext';

const Contact = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState('');
  const { t } = useAppContext();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');

    try {
      const response = await fetch('https://hook.eu2.make.com/rdrnji4l0ca3myfv6nlw81dn2etz122g', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name, email, message }),
      });

      if (!response.ok) {
        throw new Error(t("failedToSendMessage"));
      }

      setIsSuccess(true);
      setName('');
      setEmail('');
      setMessage('');
    } catch (err) {
      setError(t("errorOccurred"));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <section id="contact" className="py-20">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-bold text-center mb-12">{t("getInTouch")} ✉️</h2>
        {isSuccess ? (
          <div className="max-w-lg mx-auto text-center text-green-600 font-bold">
            {t("thankYouMessage")}
          </div>
        ) : (
          <form onSubmit={handleSubmit} className="max-w-lg mx-auto">
            <div className="mb-4">
              <label htmlFor="name" className="block mb-2 font-bold">{t("name")}</label>
              <input 
                type="text" 
                id="name" 
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-primary" 
                required 
              />
            </div>
            <div className="mb-4">
              <label htmlFor="email" className="block mb-2 font-bold">{t("email")}</label>
              <input 
                type="email" 
                id="email" 
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-primary" 
                required 
              />
            </div>
            <div className="mb-4">
              <label htmlFor="message" className="block mb-2 font-bold">{t("message")}</label>
              <textarea 
                id="message" 
                rows="4" 
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-primary" 
                required
              ></textarea>
            </div>
            {error && <p className="text-red-500 mb-4">{error}</p>}
            <button 
              type="submit" 
              className="w-full bg-primary text-white font-bold py-2 px-4 rounded-lg hover:bg-opacity-90 transition duration-300"
              disabled={isLoading}
            >
              {isLoading ? t("sending") : t("sendMessage")}
            </button>
          </form>
        )}
      </div>
    </section>
  );
};

export default Contact;