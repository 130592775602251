import React from 'react';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import { useAppContext } from '../../AppContext';

const containerStyle = {
  width: '100%',
  height: '400px'
};

const center = {
  lat: 20,
  lng: 0
};

const locations = [
    // Spain
    { "name": "Barcelona", "position": { "lat": 41.3851, "lng": 2.1734 } },
    { "name": "Madrid", "position": { "lat": 40.4168, "lng": -3.7038 } },
    { "name": "Ibiza", "position": { "lat": 38.9067, "lng": 1.4206 } },
    { "name": "Mallorca", "position": { "lat": 39.6953, "lng": 3.0176 } },
    { "name": "Zaragoza", "position": { "lat": 41.6488, "lng": -0.8891 } },
    { "name": "Seville", "position": { "lat": 37.3891, "lng": -5.9845 } },
    { "name": "Granada", "position": { "lat": 37.1773, "lng": -3.5986 } },
    { "name": "Bilbao", "position": { "lat": 43.2630, "lng": -2.9350 } },
  
    // Portugal
    { "name": "Lisbon", "position": { "lat": 38.7223, "lng": -9.1393 } },
    { "name": "Lagos", "position": { "lat": 37.1022, "lng": -8.6740 } },
    { "name": "Porto", "position": { "lat": 41.1579, "lng": -8.6291 } },
  
    // France
    { "name": "Nice", "position": { "lat": 43.7102, "lng": 7.2620 } },
  
    // Italy
    { "name": "Tuscany", "position": { "lat": 43.7711, "lng": 11.2486 } },
    { "name": "Rome", "position": { "lat": 41.9028, "lng": 12.4964 } },
    { "name": "Florence", "position": { "lat": 43.7696, "lng": 11.2558 } },
    { "name": "Amalfi Coast", "position": { "lat": 40.6333, "lng": 14.6020 } },
  
    // Croatia
    { "name": "Split", "position": { "lat": 43.5081, "lng": 16.4402 } },
    { "name": "Hvar", "position": { "lat": 43.1729, "lng": 16.4424 } },
    { "name": "Brac", "position": { "lat": 43.3079, "lng": 16.6518 } },
    { "name": "Vis", "position": { "lat": 43.0481, "lng": 16.1824 } },
  
    // UK
    { "name": "London", "position": { "lat": 51.5074, "lng": -0.1278 } },
  
    // Sweden
    { "name": "Stockholm", "position": { "lat": 59.3293, "lng": 18.0686 } },
  
    // Netherlands
    { "name": "Amsterdam", "position": { "lat": 52.3676, "lng": 4.9041 } },
  
    // Greece
    { "name": "Athens", "position": { "lat": 37.9838, "lng": 23.7275 } },
    { "name": "Naxos", "position": { "lat": 37.1000, "lng": 25.3764 } },
    { "name": "Paros", "position": { "lat": 37.0856, "lng": 25.1483 } },
    { "name": "Antiparos", "position": { "lat": 37.0333, "lng": 25.0833 } },
    { "name": "Ios", "position": { "lat": 36.7195, "lng": 25.3350 } },
    { "name": "Mykonos", "position": { "lat": 37.4467, "lng": 25.3289 } },
    { "name": "Samos", "position": { "lat": 37.7540, "lng": 26.9784 } },
  
    // Morocco
    { "name": "Casablanca", "position": { "lat": 33.5731, "lng": -7.5898 } },
    { "name": "Marrakesh", "position": { "lat": 31.6295, "lng": -7.9811 } },
  
    // Germany
    { "name": "Berlin", "position": { "lat": 52.5200, "lng": 13.4050 } },
  
    // Thailand
    { "name": "Chiang Mai", "position": { "lat": 18.7883, "lng": 98.9853 } },
    { "name": "Krabi", "position": { "lat": 8.0863, "lng": 98.9063 } },
    { "name": "Phuket", "position": { "lat": 7.8804, "lng": 98.3923 } },
    { "name": "Bangkok", "position": { "lat": 13.7563, "lng": 100.5018 } },
    { "name": "Koh Tao", "position": { "lat": 10.0970, "lng": 99.8382 } },
    { "name": "Koh Pha Ngan", "position": { "lat": 9.7313, "lng": 100.0136 } },
    { "name": "Koh Samui", "position": { "lat": 9.5120, "lng": 100.0136 } },
    { "name": "Koh Phi Phi", "position": { "lat": 7.7458, "lng": 98.7784 } },
    { "name": "Koh Chang", "position": { "lat": 12.0446, "lng": 102.3236 } },
  
    // Indonesia
    { "name": "Lombok", "position": { "lat": -8.6500, "lng": 116.3249 } },
    { "name": "Bali", "position": { "lat": -8.3405, "lng": 115.0920 } },
    { "name": "Nusa Penida", "position": { "lat": -8.7274, "lng": 115.5444 } },
    { "name": "Gili Trawangan", "position": { "lat": -8.3515, "lng": 116.0556 } },
    { "name": "Gili Meno", "position": { "lat": -8.3480, "lng": 116.0563 } },
    { "name": "Gili Air", "position": { "lat": -8.3500, "lng": 116.0695 } },
  
    // Malaysia
    { "name": "Penang", "position": { "lat": 5.4164, "lng": 100.3327 } },
    { "name": "Cameron Highlands", "position": { "lat": 4.4708, "lng": 101.3761 } },
  
    // Singapore
    { "name": "Singapore", "position": { "lat": 1.3521, "lng": 103.8198 } },
  
    // Vietnam
    { "name": "Halong Bay", "position": { "lat": 20.9101, "lng": 107.1839 } },
    { "name": "Hanoi", "position": { "lat": 21.0285, "lng": 105.8542 } },
  
    // Hawaii (USA)
    { "name": "O'ahu", "position": { "lat": 21.4389, "lng": -158.0001 } },
    { "name": "Maui", "position": { "lat": 20.7984, "lng": -156.3319 } },

    // Puerto Rico (USA)
  { "name": "San Juan", "position": { "lat": 18.4655, "lng": -66.1057 } },

  // USA (Continental)
  { "name": "Miami", "position": { "lat": 25.7617, "lng": -80.1918 } },
  { "name": "Orlando", "position": { "lat": 28.5383, "lng": -81.3792 } },
  { "name": "Phoenix", "position": { "lat": 33.4484, "lng": -112.0740 } },
  { "name": "Los Angeles", "position": { "lat": 34.0522, "lng": -118.2437 } },
  { "name": "San Francisco", "position": { "lat": 37.7749, "lng": -122.4194 } },
  { "name": "San Diego", "position": { "lat": 32.7157, "lng": -117.1611 } },
  { "name": "Boston", "position": { "lat": 42.3601, "lng": -71.0589 } },
  { "name": "Washington D.C.", "position": { "lat": 38.9072, "lng": -77.0369 } },
  { "name": "Charleston", "position": { "lat": 32.7765, "lng": -79.9311 } },
  { "name": "Savannah", "position": { "lat": 32.0809, "lng": -81.0912 } },
  { "name": "Houston", "position": { "lat": 29.7604, "lng": -95.3698 } },
  { "name": "Austin", "position": { "lat": 30.2672, "lng": -97.7431 } },
  { "name": "Seattle", "position": { "lat": 47.6062, "lng": -122.3321 } },
  { "name": "Chicago", "position": { "lat": 41.8781, "lng": -87.6298 } },

  // China
  { "name": "Shanghai", "position": { "lat": 31.2304, "lng": 121.4737 } },

  // Canada
  { "name": "Toronto", "position": { "lat": 43.6532, "lng": -79.3832 } },

  // Czechia
  { "name": "Prague", "position": { "lat": 50.0755, "lng": 14.4378 } },

  // Mexico
  { "name": "Cabo San Lucas", "position": { "lat": 22.8905, "lng": -109.9167 } },
  { "name": "Sayulita", "position": { "lat": 20.8686, "lng": -105.4407 } },
  { "name": "Puerto Escondido", "position": { "lat": 15.8570, "lng": -97.0656 } }
];

function TravelMap() {

  const { t } = useAppContext();

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyB0pwAmhx-lDl1UqLS9Z7D5_wNDdwJ8ot8"
  });

  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback(function callback(map) {
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  return isLoaded ? (
    <section id="travel-map" className="py-20 bg-background">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-bold text-center mb-12 text-text">{t("placesTitle")}</h2>
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={2}
          onLoad={onLoad}
          onUnmount={onUnmount}
        >
          {locations.map((location, index) => (
            <Marker 
              key={index} 
              position={location.position} 
              title={location.name}
            />
          ))}
        </GoogleMap>
      </div>
    </section>
  ) : <></>
}

export default React.memo(TravelMap);