import React from 'react';
import StarRating from './StarRating';
import { useAppContext } from '../../AppContext';

const TestimonialCard = ({ text, author, image, rating }) => {
  const { t } = useAppContext();
  return (
    <div className="bg-white p-6 rounded-lg shadow-md flex flex-col items-center">
      <img 
        src={image} 
        alt={author} 
        className="w-24 h-24 rounded-full object-cover mb-4 border-2 border-primary"
      />
      <StarRating rating={rating} />
      <p className="italic my-4 text-center">{t(text)}</p>
      <p className="font-bold text-primary">{author}</p>
    </div>
  );
};

const Testimonials = () => {
  const { t } = useAppContext();

  const testimonials = [
    { 
      text: "patrickTestimonial", 
      author: "Patrick N.", 
      image: "/images/patrick.webp",
      rating: 5
    },
    { 
      text: "eileenTestimonial", 
      author: "Eileen J.", 
      image: "/images/eileen.webp",
      rating: 5
    },
    { 
      text: "lesleeTestimonial", 
      author: "Leslee G.", 
      image: "/images/leslee.webp",
      rating: 5
    },
  ];

  return (
    <section id="testimonials" className="py-20 bg-background">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-bold text-center mb-12 text-text">{t("clientTestimonials")}</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {testimonials.map((testimonial, index) => (
            <TestimonialCard key={index} {...testimonial} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Testimonials;