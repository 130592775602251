import React from 'react';
import { Linkedin, MapPin, Briefcase, School } from 'lucide-react';
import { useAppContext } from '../AppContext';

const LinkedInCard = () => {
  const { t } = useAppContext();

  return (
    <div className="bg-white shadow-lg rounded-lg overflow-hidden max-w-md mx-auto">
      <div className="bg-blue-600 h-24"></div>
      <div className="p-6">
        <div className="relative">
          <img
            src="/images/morgan.jpeg"
            alt="Morgan Mathien"
            className="w-32 h-32 rounded-full border-4 border-white absolute -top-16 left-1/2 transform -translate-x-1/2"
          />
        </div>
        <div className="mt-16 text-center">
          <h2 className="text-2xl font-bold text-gray-800">Morgan Mathien</h2>
          <p className="text-gray-600 mt-1">{t("linkedinCardTitle")}</p>
          <p className="text-gray-500 mt-1 flex items-center justify-center">
            <MapPin size={16} className="mr-1" /> {t("linkedinCardLocation")}
          </p>
        </div>
        <div className="mt-6">
          <p className="text-gray-700 text-sm">
            {t("linkedinCardDescription")}
          </p>
        </div>
        <div className="mt-6">
          <div className="flex items-center text-gray-700 mb-2">
            <Briefcase size={16} className="mr-2" />
            <span className="text-sm">{t("linkedinCardCompany")}</span>
          </div>
          <div className="flex items-center text-gray-700">
            <School size={16} className="mr-2" />
            <span className="text-sm">{t("linkedinCardEducation")}</span>
          </div>
        </div>
        <div className="mt-6">
          <a
            href="https://www.linkedin.com/in/morganmathien/"
            target="_blank"
            rel="noopener noreferrer"
            className="bg-blue-600 text-white py-2 px-4 rounded-full flex items-center justify-center hover:bg-blue-700 transition duration-300"
          >
            <Linkedin size={20} className="mr-2" />
            {t("viewLinkedInProfile")}
          </a>
        </div>
      </div>
    </div>
  );
};

export default LinkedInCard;