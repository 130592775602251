import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import { AppProvider, useAppContext } from './AppContext';
import Header from './components/commons/Header';
import Home from './components/home/Home';
import TermsOfUse from './components/policies/TermsOfUse';
import PrivacyPolicy from './components/policies/PrivacyPolicy';
import CookiePolicy from './components/policies/CookiePolicy';
import Footer from './components/commons/Footer';
import ProtectedRoute from './components/commons/ProtectedRoute';
import BookingPage from './components/home/BookingPage';

function AppContent() {
  const [showAssistant, setShowAssistant] = useState(false);
  const [showSaveTripModal, setShowSaveTripModal] = useState(false);
  const [currentTrip, setCurrentTrip] = useState(null);
  const { user } = useAppContext();
  const navigate = useNavigate();

  const handleCloseAssistant = (trip) => {
    setCurrentTrip(trip);
    setShowAssistant(false);
    setShowSaveTripModal(true);
  };

  const handleSaveTrip = () => {
    // Implement saving trip to Firebase here
    console.log('Saving trip:', currentTrip);
    setShowSaveTripModal(false);
  };

  const handleSignUp = () => {
    // Store the current trip in local storage or app state
    localStorage.setItem('pendingTrip', JSON.stringify(currentTrip));
    // Redirect to sign up page
    navigate('/signup');
    setShowSaveTripModal(false);
  };

  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <main className="flex-grow">
        <Routes>
          <Route path="/" element={<Home onStartJourney={() => setShowAssistant(true)} />} />

        <Route path="/book/:packageType" element={<BookingPage />} />
          <Route path="/profile" element={
            <ProtectedRoute>
            </ProtectedRoute>
          } />
          <Route path="/terms-of-use" element={<TermsOfUse />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/cookie-policy" element={<CookiePolicy />} />
        </Routes>
      </main>
      <Footer />
    </div>
  );
}

function App() {
  return (
    <AppProvider>
      <Router>
        <AppContent />
      </Router>
    </AppProvider>
  );
}

export default App;