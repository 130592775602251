import React from 'react';
import { useAppContext } from '../../AppContext';
import useScrollToTop from '../../hooks/useScrollToTop';

const PrivacyPolicy = () => {
  const { t } = useAppContext();
  useScrollToTop();

  return (
    <div className="container mx-auto px-4 py-8 mt-16">
      <h1 className="text-3xl font-bold mb-6">{t("privacyPolicy")}</h1>
      
      <p className="mb-4">{t("privacyPolicyIntro")}</p>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">{t("informationCollection")}</h2>
        <p className="mb-4">{t("informationCollectionDesc")}</p>
        <p className="mb-4">{t("additionalInfoCollected")}</p>
        <ul className="list-disc pl-8 mb-4">
          <li>{t("contactInfo")}</li>
          <li>{t("travelPreferences")}</li>
          <li>{t("paymentInfo")}</li>
          <li>{t("customerServiceInfo")}</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">{t("useOfInformation")}</h2>
        <p className="mb-4">{t("useOfInfoDesc")}</p>
        <p className="mb-4">{t("additionalUseOfInfo")}</p>
        <ul className="list-disc pl-8 mb-4">
          <li>{t("personalizeExperience")}</li>
          <li>{t("processTransactions")}</li>
          <li>{t("sendEmails")}</li>
          <li>{t("respondInquiries")}</li>
          <li>{t("improveWebsite")}</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">{t("dataSecurity")}</h2>
        <p className="mb-4">{t("dataSecurityDesc")}</p>
        <p className="mb-4">{t("securityMeasures")}</p>
        <ul className="list-disc pl-8 mb-4">
          <li>{t("encryption")}</li>
          <li>{t("securityAudits")}</li>
          <li>{t("restrictedAccess")}</li>
          <li>{t("employeeTraining")}</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">{t("thirdPartyDisclosure")}</h2>
        <p className="mb-4">{t("thirdPartyDisclosureDesc")}</p>
        <p className="mb-4">{t("infoSharing")}</p>
        <ul className="list-disc pl-8 mb-4">
          <li>{t("serviceProviders")}</li>
          <li>{t("travelPartners")}</li>
          <li>{t("legalAuthorities")}</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">{t("cookies")}</h2>
        <p className="mb-4">{t("cookiesDesc")}</p>
        <p className="mb-4">{t("cookiesUsage")}</p>
        <ul className="list-disc pl-8 mb-4">
          <li>{t("rememberPreferences")}</li>
          <li>{t("understandUsage")}</li>
          <li>{t("improveServices")}</li>
          <li>{t("providePersonalizedContent")}</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">{t("gdprCompliance")}</h2>
        <p className="mb-4">{t("gdprComplianceDesc")}</p>
        <p className="mb-4">{t("gdprRights")}</p>
        <ul className="list-disc pl-8 mb-4">
          <li>{t("rightToRestrict")}</li>
          <li>{t("rightToDataPortability")}</li>
          <li>{t("rightToObject")}</li>
          <li>{t("rightToWithdrawConsent")}</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">{t("childrenPrivacy")}</h2>
        <p className="mb-4">{t("childrenPrivacyDesc")}</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">{t("changesPrivacyPolicy")}</h2>
        <p className="mb-4">{t("changesPrivacyPolicyDesc")}</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">{t("contactUs")}</h2>
        <p className="mb-4">{t("contactUsDesc")}</p>
        <p>
          Morgan Mathien<br />
          {t("email")}: morganmathien@gmail.com<br />
          {t("phone")}: +1(716) 548-1856<br />
          {t("mobile")}: +62(823) 4202-2797<br />
          Whatsapp: +30(694) 664-8569
        </p>
      </section>

      <p className="text-sm text-gray-600">
        {t("lastUpdated")} {new Date().toLocaleDateString()}
      </p>
    </div>
  );
};

export default PrivacyPolicy;