import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAppContext } from '../../AppContext';

const ProtectedRoute = ({ children }) => {
  const { user } = useAppContext();

  if (!user) {
    // Si el usuario no está autenticado, redirige a la página de inicio
    return <Navigate to="/" replace />;
  }

  return children;
};

export default ProtectedRoute;