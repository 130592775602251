import React, { useEffect } from 'react';

const CalendlyWidget = ({ url }) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.innerHTML = `Calendly.initInlineWidget({
      url: '${url}',
      parentElement: document.getElementById('calendly-inline-widget'),
      prefill: {},
      utm: {}
    });`;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [url]);

  return (
    <div id="calendly-inline-widget" style={{minWidth:'320px',height:'630px'}}></div>
  );
};

export default CalendlyWidget;