import React from 'react';
import { Link } from 'react-router-dom';
import { useAppContext } from '../../AppContext';

const Footer = () => {
  const { t } = useAppContext();

  return (
    <footer className="bg-gray-800 text-white py-12">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-2 gap-8 sm:gap-16 justify-items-center sm:justify-items-start">
          <div className="text-center sm:text-left">
            <h3 className="text-lg font-bold mb-4">{t("legal")}</h3>
            <ul className="space-y-2">
              <li><Link to="/privacy-policy" className="hover:text-primary">{t("privacyPolicy")}</Link></li>
              <li><Link to="/terms-of-use" className="hover:text-primary">{t("termsOfUse")}</Link></li>
              <li><Link to="/cookie-policy" className="hover:text-primary">{t("cookiePolicy")}</Link></li>
            </ul>
          </div>
          <div className="text-center sm:text-left">
            <h3 className="text-lg font-bold mb-4">{t("contactInfo")}</h3>
            <ul className="space-y-2">
              <li><a href="mailto:morganmathien@gmail.com" className="hover:text-primary">✉️ morganmathien@gmail.com</a></li>
              <li><a href="tel:+17165481856" className="hover:text-primary">📞 +1(716) 548-1856</a></li>
              <li><a href="tel:+6282342022797" className="hover:text-primary">📞 +62(823) 4202-2797</a></li>
              <li><a href="https://wa.me/306946648569" target="_blank" rel="noopener noreferrer" className="hover:text-primary">💬 +30(694) 664-8569</a></li>
            </ul>
          </div>
        </div>
        <div className="mt-8 pt-8 border-t border-gray-700 text-center">
          <div className="flex justify-center space-x-4 mb-4">
            <a href="#" className="text-2xl hover:text-primary"><i className="fab fa-facebook"></i></a>
            <a href="#" className="text-2xl hover:text-primary"><i className="fab fa-twitter"></i></a>
            <a href="#" className="text-2xl hover:text-primary"><i className="fab fa-instagram"></i></a>
            <a href="#" className="text-2xl hover:text-primary"><i className="fab fa-linkedin"></i></a>
          </div>
          <p>{t("copyrightNotice")}</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;