import React from 'react';
import { useAppContext } from '../../AppContext';
import useScrollToTop from '../../hooks/useScrollToTop';

const TermsOfUse = () => {
  const { t } = useAppContext();
  useScrollToTop();

  return (
    <div className="container mx-auto px-4 py-8 mt-16">
      <h1 className="text-3xl font-bold mb-6">{t("termsOfUse")}</h1>
      
      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">{t("acceptanceOfTerms")}</h2>
        <p className="mb-4">{t("acceptanceTermsDesc")}</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">{t("intellectualProperty")}</h2>
        <p className="mb-4">{t("intellectualPropertyDesc")}</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">{t("userConduct")}</h2>
        <p className="mb-4">{t("userConductDesc1")}</p>
        <p className="mb-4">{t("userConductDesc2")}</p>
        <ul className="list-disc pl-8 mb-4">
          <li>{t("conductRule1")}</li>
          <li>{t("conductRule2")}</li>
          <li>{t("conductRule3")}</li>
          <li>{t("conductRule4")}</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">{t("limitationOfLiability")}</h2>
        <p className="mb-4">{t("limitationLiabilityDesc1")}</p>
        <p className="mb-4">{t("limitationLiabilityDesc2")}</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">{t("modificationsToTerms")}</h2>
        <p className="mb-4">{t("modificationsTermsDesc")}</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">{t("governingLaw")}</h2>
        <p className="mb-4">{t("governingLawDesc")}</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">{t("contactInformation")}</h2>
        <p className="mb-4">{t("contactInfoDesc")}</p>
        <p>
          Morgan Mathien<br />
          {t("email")}: morganmathien@gmail.com<br />
          {t("phone")}: +1(716) 548-1856<br />
          {t("mobile")}: +62(823) 4202-2797<br />
          Whatsapp: +30(694) 664-8569
        </p>
      </section>

      <p className="text-sm text-gray-600">
        {t("lastUpdated")} {new Date().toLocaleDateString()}
      </p>
    </div>
  );
};

export default TermsOfUse;