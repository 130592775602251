import React from 'react';
import { Clock, Users, Briefcase, Check } from 'lucide-react';
import { useAppContext } from '../../AppContext';

const ServiceCard = ({ icon: Icon, title, description, features, price }) => {
  const { t } = useAppContext();
  return (
    <div className="bg-white p-6 rounded-lg shadow-md text-center flex flex-col h-full transition duration-300 hover:shadow-lg">
      <Icon size={48} className="text-accent mx-auto mb-4" />
      <h3 className="text-xl font-bold mb-2 text-primary">{title}</h3>
      <p className="mb-4 text-text-light">{description}</p>
      <ul className="text-left mb-4 flex-grow">
        {features.map((feature, index) => (
          <li key={index} className="flex items-center mb-2">
            <Check size={20} className="text-accent mr-2" />
            <span className="text-text">{feature}</span>
          </li>
        ))}
      </ul>
      <p className="font-bold text-lg text-primary">{price}</p>
      <button className="mt-4 bg-accent text-white font-bold py-2 px-4 rounded-full hover:bg-accent-light transition duration-300">
        {t("getStarted")}
      </button>
    </div>
  );
};

const Packages = () => {
  const { t } = useAppContext();

  const services = [
    { 
      icon: Clock, 
      title: t("hourlyConsultation"), 
      description: t("hourlyConsultationDesc"), 
      features: [
        t("oneOnOneExpertConsultation"),
        t("tailoredAdvice"),
        t("flexibleScheduling"),
        t("noLongTermCommitment")
      ],
      price: t("startingAt25PerHour")
    },
    { 
      icon: Briefcase, 
      title: t("projectBasedSolutions"), 
      description: t("projectBasedSolutionsDesc"), 
      features: [
        t("inDepthBusinessAnalysis"),
        t("customStrategyDevelopment"),
        t("implementationSupport"),
        t("projectManagementAndReporting")
      ],
      price: t("customQuoteBasedOnProjectScope")
    },
    { 
      icon: Users, 
      title: t("retainerServices"), 
      description: t("retainerServicesDesc"), 
      features: [
        t("dedicatedBusinessDevelopmentSupport"),
        t("regularStrategySessions"),
        t("continuousImprovementInitiatives"),
        t("priorityAccessToOurExpertise")
      ],
      price: t("startingAt500PerMonth")
    }
  ];

  return (
    <section id="packages" className="py-20 bg-background-dark">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-bold text-center mb-12 text-primary">{t("ourServicePackages")}</h2>
        <p className="text-xl text-center mb-12 text-text-light">{t("chooseThePerfectPackage")}</p>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {services.map((service, index) => (
            <ServiceCard key={index} {...service} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Packages;