import React from 'react';
import { useParams } from 'react-router-dom';
import CalendlyWidget from './CalendlyWidget';
import useScrollToTop from '../../hooks/useScrollToTop';

const BookingPage = () => {

  useScrollToTop();
  const { packageType } = useParams();

  const calendlyUrls = {
    'time-based': 'https://calendly.com/morganmathien/client-update?back=1&month=2024-08',
    'partners': 'https://calendly.com/morganmathien/client-update?back=1&month=2024-08',
    'ultimate': 'https://calendly.com/morganmathien/client-update?back=1&month=2024-08'
  };

  return (
    <div className="container mx-auto px-4 py-8 mt-16">
      <h1 className="text-3xl font-bold mb-6 text-center">Book Your Meeting</h1>
      <CalendlyWidget url={calendlyUrls[packageType]} />
    </div>
  );
};

export default BookingPage;