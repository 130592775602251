import React from 'react';
import { Star } from 'lucide-react';

const StarRating = ({ rating }) => {
  return (
    <div className="flex">
      {[...Array(5)].map((_, index) => (
        <Star
          key={index}
          size={20}
          className={index < rating ? "text-yellow-400 fill-current" : "text-gray-300"}
        />
      ))}
    </div>
  );
};

export default StarRating;