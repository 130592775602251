// src/translations/de.js
export default {
    // Header
    "packages": "Pakete",
    "services": "Dienstleistungen",
    "about": "Über uns",
    "contact": "Kontakt",
    "letsTalk": "Lass uns reden",
  
    // Hero
    "elevateYourBusinessGrowth": "Steigern Sie Ihr Unternehmenswachstum",
    "heroDescription": "Erleben Sie strategische mit personalisierten Lösungen. Lassen Sie uns Herausforderungen in Chancen verwandeln.",
    "startYourJourney": "Starten Sie Ihre Reise",
  
    // Services
    "elevateYourBusinessWithExpertSolutions": "Steigern Sie Ihr Geschäft mit Expertenlösungen",
    "servicesDescription": "Hallo, ich bin Morgan, Ihre engagierte Geschäftsentwicklungsberaterin. Mit einem reichen globalen Erfahrungsschatz und vielseitigen Fähigkeiten bin ich hier, um Ihrem Unternehmen zu helfen, in der heutigen wettbewerbsintensiven Landschaft zu gedeihen. Meine Dienstleistungen sind darauf ausgerichtet, Ihre Abläufe zu optimieren, neue Möglichkeiten zu entdecken und nachhaltiges Wachstum zu fördern.",
    "businessGrowthStrategy": "Geschäftswachstumsstrategie",
    "businessGrowthStrategyDesc": "Entwicklung maßgeschneiderter Strategien, um Ihr Unternehmen voranzutreiben und nachhaltiges Wachstum zu erzielen.",
    "customerServiceSupport": "Kundenservice-Unterstützung",
    "customerServiceSupportDesc": "Verbesserung Ihrer Kundenerfahrung durch Expertenunterstützung und Beziehungsmanagement.",
    "administrativeSupport": "Administrative Unterstützung",
    "administrativeSupportDesc": "Optimierung Ihrer Abläufe mit effizienten administrativen und organisatorischen Lösungen.",
    "internationalBusinessDevelopment": "Internationale Geschäftsentwicklung",
    "internationalBusinessDevelopmentDesc": "Nutzung globaler Expertise zur Expansion Ihres Unternehmens über Grenzen hinweg.",
    "salesDevelopment": "Vertriebsentwicklung",
    "salesDevelopmentDesc": "Implementierung effektiver Verkaufsstrategien und -prozesse zur Steigerung Ihres Umsatzes.",
    "marketResearch": "Marktforschung",
    "marketResearchDesc": "Gewinnung wertvoller Erkenntnisse durch umfassende Marktforschung und -analyse.",
  
    // About
    "aboutMorgan": "Über ",
    "aboutDescription": "Hallo, ich bin Morgan und ich bin leidenschaftlich daran interessiert, Unternehmenswachstum voranzutreiben und globale Wirkung zu erzielen. Mit einem vielfältigen Hintergrund in Reisemanagement, Eventkoordination, Kundenservice und Geschäftsentwicklung habe ich einzigartige Fähigkeiten entwickelt, die es mir ermöglichen, komplexe Herausforderungen zu meistern und gleichzeitig Möglichkeiten für Expansion und positive Veränderungen zu identifizieren.",
    "myMission": "Meine Mission",
    "missionDescription": "Ich arbeite mit Wellness-Marken und Abenteuerunternehmen zusammen, um Wachstum zu fördern, globale Wirkung zu erzielen und positive Veränderungen zu inspirieren. Durch die Nutzung meiner Expertise in Geschäftsentwicklung, Gesundheit, Ernährung und Abenteuer entwickle ich maßgeschneiderte Strategien, die die Markensichtbarkeit erhöhen, authentische Partnerschaften aufbauen und ganzheitliches Wohlbefinden fördern.",
    "ourCoreValues": "Unsere Kernwerte",
    "resultsDriven": "Ergebnisorientiert",
    "resultsDrivenDesc": "Wir konzentrieren uns darauf, greifbare und messbare Ergebnisse für Ihr Unternehmen zu erzielen.",
    "innovativeSolutions": "Innovative Lösungen",
    "innovativeSolutionsDesc": "Wir bringen frische, kreative Ideen ein, um die Herausforderungen Ihres Unternehmens zu bewältigen.",
    "clientCentricApproach": "Kundenorientierter Ansatz",
    "clientCentricApproachDesc": "Ihr Erfolg steht im Mittelpunkt all unserer Aktivitäten.",
    "globalPerspective": "Globale Perspektive",
    "globalPerspectiveDesc": "Wir nutzen internationale Erfahrung, um Ihrem Unternehmen einen Wettbewerbsvorteil zu verschaffen.",
    "continuousImprovement": "Kontinuierliche Verbesserung",
    "continuousImprovementDesc": "Wir entwickeln unsere Strategien ständig weiter, um in der sich ständig verändernden Geschäftslandschaft an der Spitze zu bleiben.",
    "integrityTransparency": "Integrität und Transparenz",
    "integrityTransparencyDesc": "Wir glauben an ehrliche, offene Kommunikation und ethische Geschäftspraktiken.",
    "wellnessFocus": "Fokus auf Wohlbefinden",
    "wellnessFocusDesc": "Wir fördern einen gesünderen Lebensstil durch unsere Geschäftsstrategien.",
    "adventureSpirit": "Abenteuergeist",
    "adventureSpiritDesc": "Wir bringen eine abenteuerliche Denkweise in das Unternehmenswachstum ein.",
    "letsCreateImpactTogether": "Lassen Sie uns gemeinsam Wirkung erzielen",
    "impactDescription": "Ob Sie eine Wellness-Marke, ein Abenteuerunternehmen oder ein Unternehmen sind, das einen globalen Unterschied machen möchte - lassen Sie uns gemeinsam diese Reise antreten und außergewöhnliches Wachstum und positive Veränderungen schaffen!",

    // Packages
    "ourServicePackages": "Unsere Servicepakete",
    "chooseThePerfectPackage": "Wählen Sie das perfekte Paket, um Ihr Unternehmenswachstum zu beschleunigen",
    "hourlyConsultation": "Stundenkonsultation",
    "hourlyConsultationDesc": "Flexible, bedarfsorientierte Geschäftsentwicklungsberatung und Unterstützung.",
    "projectBasedSolutions": "Projektbasierte Lösungen",
    "projectBasedSolutionsDesc": "Umfassende Unterstützung für spezifische Geschäftswachstumsinitiativen.",
    "retainerServices": "Dauermandatsdienste",
    "retainerServicesDesc": "Laufende strategische Partnerschaft für kontinuierliche Geschäftsverbesserung und Wachstum.",
    "oneOnOneExpertConsultation": "Individuelle Expertenberatung",
    "tailoredAdvice": "Maßgeschneiderte Beratung für Ihre Geschäftsbedürfnisse",
    "flexibleScheduling": "Flexible Terminplanung",
    "noLongTermCommitment": "Keine langfristige Verpflichtung erforderlich",
    "inDepthBusinessAnalysis": "Eingehende Geschäftsanalyse",
    "customStrategyDevelopment": "Entwicklung maßgeschneiderter Strategien",
    "implementationSupport": "Implementierungsunterstützung",
    "projectManagementAndReporting": "Projektmanagement und Berichterstattung",
    "dedicatedBusinessDevelopmentSupport": "Dedizierte Unterstützung bei der Geschäftsentwicklung",
    "regularStrategySessions": "Regelmäßige Strategiesitzungen",
    "continuousImprovementInitiatives": "Initiativen zur kontinuierlichen Verbesserung",
    "priorityAccessToOurExpertise": "Vorrangiger Zugang zu unserer Expertise",
    "startingAt25PerHour": "Ab 25€ pro Stunde",
    "customQuoteBasedOnProjectScope": "Individuelles Angebot basierend auf dem Projektumfang",
    "startingAt500PerMonth": "Ab 500€ pro Monat",
    "getStarted": "Jetzt starten",

    // Testimonials
    "clientTestimonials": "Kundenbewertungen",
    "patrickTestimonial": "Die Zusammenarbeit mit Morgan war ein Wendepunkt für mein Unternehmen. Sie ist unglaublich organisiert, proaktiv und bringt immer frische Ideen ein. Ob es um die Optimierung von Prozessen, die Verwaltung der Kundenkommunikation oder die Unterstützung bei der Strategie geht, Morgan erledigt alles mit Professionalität und Leichtigkeit. Sie war ein großer Gewinn und ich kann sie nicht genug empfehlen!",
    "eileenTestimonial": "Ich bin äußerst zufrieden mit Morgans erstaunlichen Familienreisediensten. Ihre sorgfältige Planung und ihr Talent, unvergessliche Erlebnisse zu schaffen, übertrafen unsere Erwartungen bei weitem. Dank ihr wurde unsere Reise zu einer wirklich besonderen Familienerinnerung.",
    "lesleeTestimonial": "Morgan war eine absolute Rettung, als es darum ging, meine Verkäufe zu steigern. Ihre Fähigkeit, zu organisieren, Leads zu verfolgen und potenzielle Kunden nachzuverfolgen, hat mir viel Arbeit abgenommen. Sie ist immer zwei Schritte voraus und stellt sicher, dass nichts durch die Ritzen fällt. Ihre Einsichten und Unterstützung haben einen echten Einfluss auf mein Geschäft gehabt. Ich hätte meine Verkaufsbemühungen ohne sie nicht skalieren können und bin so dankbar für ihre Hilfe!",

    // FAQ
    "frequentlyAskedQuestions": "Häufig gestellte Fragen",
    "howCanYourServicesHelp": "Wie können Ihre Dienstleistungen meinem Unternehmen beim Wachstum helfen?",
    "howCanYourServicesHelpAnswer": "Meine Dienstleistungen sind darauf ausgerichtet, Wachstumschancen zu identifizieren, Abläufe zu optimieren und effektive Strategien umzusetzen. Ich werde eng mit Ihnen zusammenarbeiten, um Ihre Geschäftsziele zu verstehen und maßgeschneiderte Lösungen zu entwickeln, um diese zu erreichen, sei es durch Marktforschung, Vertriebsentwicklung oder Verbesserungen der betrieblichen Effizienz.",
    "whatTypesOfBusinesses": "Mit welchen Arten von Unternehmen arbeiten Sie?",
    "whatTypesOfBusinessesAnswer": "Ich arbeite mit einer breiten Palette von Unternehmen, von Start-ups bis hin zu etablierten Firmen, in verschiedenen Branchen. Meine vielfältige Erfahrung ermöglicht es mir, meinen Ansatz an die einzigartigen Bedürfnisse jedes Kunden anzupassen, unabhängig von seiner Größe oder Branche.",
    "howLongToSeeResults": "Wie lange dauert es typischerweise, bis man Ergebnisse sieht?",
    "howLongToSeeResultsAnswer": "Die Zeitspanne bis zum Sichtbarwerden von Ergebnissen kann je nach den spezifischen Zielen und Herausforderungen Ihres Unternehmens variieren. Einige Verbesserungen, wie betriebliche Effizienzsteigerungen, können relativ schnell sichtbar werden. Bei größeren strategischen Initiativen kann es mehrere Monate dauern, bis signifikante Ergebnisse zu sehen sind. Ich werde mit Ihnen zusammenarbeiten, um realistische Erwartungen und Meilensteine zu setzen.",
    "howDoYouCharge": "Wie berechnen Sie Ihre Dienstleistungen?",
    "howDoYouChargeAnswer": "Meine Preisstruktur ist flexibel und hängt vom Umfang und der Dauer des Projekts ab. Ich biete sowohl projektbasierte als auch Retainer-Optionen an. Wir werden Ihre Bedürfnisse im Detail besprechen und ich werde Ihnen ein transparentes Angebot unterbreiten, das auf Ihre spezifischen Anforderungen zugeschnitten ist.",

    // Contact
    "getInTouch": "Kontaktieren Sie uns",
    "name": "Name",
    "email": "E-Mail",
    "message": "Nachricht",
    "sendMessage": "Nachricht senden",
    "sending": "Wird gesendet...",
    "thankYouMessage": "Vielen Dank für Ihre Nachricht. Wir werden uns in Kürze bei Ihnen melden!",
    "failedToSendMessage": "Nachricht konnte nicht gesendet werden",
    "errorOccurred": "Beim Senden Ihrer Nachricht ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",

    // Footer
    "legal": "Rechtliches",
    "privacyPolicy": "Datenschutzerklärung",
    "termsOfUse": "Nutzungsbedingungen",
    "cookiePolicy": "Cookie-Richtlinie",
    "contactInfo": "Kontaktinformationen",
    "copyrightNotice": "© 2024 Morgan Mathien - Werte liefern. Alle Rechte vorbehalten.",

    // Cookie Policy
    "cookiePolicyIntro": "Diese Cookie-Richtlinie erklärt, wie Morgan Mathien Cookies und ähnliche Technologien verwendet, um dich bei deinem Besuch unserer Website zu erkennen. Sie erklärt, was diese Technologien sind und warum wir sie verwenden, sowie deine Rechte, unsere Nutzung zu steuern.",
    "whatAreCookies": "Was sind Cookies?",
    "cookiesExplanation": "Cookies sind kleine Datendateien, die auf deinem Computer oder mobilen Gerät abgelegt werden, wenn du eine Website besuchst. Cookies werden von Website-Besitzern weit verbreitet verwendet, um ihre Websites funktionsfähig zu machen oder effizienter zu arbeiten, sowie um Berichtsinformationen bereitzustellen.",
    "firstThirdPartyCookies": "Von dem Website-Eigentümer (in diesem Fall Morgan Mathien) gesetzte Cookies werden als 'Erstanbieter-Cookies' bezeichnet. Cookies, die von anderen Parteien als dem Website-Eigentümer gesetzt werden, werden als 'Drittanbieter-Cookies' bezeichnet. Drittanbieter-Cookies ermöglichen die Bereitstellung von Drittanbieterfunktionen oder -funktionalitäten auf oder über die Website.",
    "whyWeUseCookies": "Warum verwenden wir Cookies?",
    "cookiesUsageExplanation": "Wir verwenden sowohl Erstanbieter- als auch Drittanbieter-Cookies aus mehreren Gründen. Einige Cookies sind aus technischen Gründen erforderlich, damit unsere Website funktioniert, und wir bezeichnen diese als 'essentielle' oder 'streng notwendige' Cookies. Andere Cookies ermöglichen es uns auch, die Interessen unserer Nutzer zu verfolgen und anzusprechen, um die Erfahrung auf unserer Website zu verbessern. Drittanbieter verwenden Cookies über unsere Website für Werbung, Analysen und andere Zwecke.",
    "typesOfCookiesWeUse": "Arten von Cookies, die wir verwenden",
    "cookieTypesIntro": "Die spezifischen Arten von Erstanbieter- und Drittanbieter-Cookies, die über unsere Website bereitgestellt werden, und die Zwecke, die sie erfüllen, sind unten beschrieben:",
    "essentialCookies": "Essentielle Website-Cookies:",
    "essentialCookiesDesc": "Diese Cookies sind unbedingt erforderlich, um dir die über unsere Website verfügbaren Dienste bereitzustellen und einige ihrer Funktionen zu nutzen.",
    "performanceCookies": "Leistungs- und Funktionalitäts-Cookies:",
    "performanceCookiesDesc": "Diese Cookies werden verwendet, um die Leistung und Funktionalität unserer Website zu verbessern, sind jedoch nicht unbedingt erforderlich für deren Nutzung.",
    "analyticsCookies": "Analyse- und Anpassungs-Cookies:",
    "analyticsCookiesDesc": "Diese Cookies sammeln Informationen, die entweder in aggregierter Form verwendet werden, um uns zu helfen zu verstehen, wie unsere Website genutzt wird, oder wie effektiv unsere Marketingkampagnen sind, oder um uns zu helfen, unsere Website für dich anzupassen.",
    "advertisingCookies": "Werbe-Cookies:",
    "advertisingCookiesDesc": "Diese Cookies werden verwendet, um Werbebotschaften relevanter für dich zu machen.",
    "socialNetworkingCookies": "Soziale Netzwerk-Cookies:",
    "socialNetworkingCookiesDesc": "Diese Cookies werden verwendet, um es dir zu ermöglichen, Seiten und Inhalte, die du auf unserer Website interessant findest, über Drittanbieter soziale Netzwerke und andere Websites zu teilen.",
    "howToControlCookies": "Wie kannst du Cookies kontrollieren?",
    "cookieControlExplanation": "Du hast das Recht zu entscheiden, ob du Cookies akzeptieren oder ablehnen möchtest. Du kannst deine Cookie-Präferenzen ausüben, indem du auf die entsprechenden Opt-out-Links im Cookie-Banner klickst.",
    "browserControlExplanation": "Du kannst auch die Einstellungen deines Webbrowsers ändern oder anpassen, um Cookies zu akzeptieren oder abzulehnen.",
    "updatesPolicy": "Wie oft werden wir diese Cookie-Richtlinie aktualisieren?",
    "policyUpdateExplanation": "Wir können diese Cookie-Richtlinie von Zeit zu Zeit aktualisieren, um beispielsweise Änderungen an den Cookies, die wir verwenden, oder aus anderen betrieblichen, rechtlichen oder regulatorischen Gründen widerzuspiegeln.",
    "moreInfo": "Wo kannst du weitere Informationen erhalten?",
    "contactForMoreInfo": "Wenn du Fragen zu unserem Einsatz von Cookies oder anderen Technologien hast, sende uns bitte eine E-Mail an:",

    // Terms of Use
    "acceptanceOfTerms": "1. Akzeptanz der Bedingungen",
    "acceptanceTermsDesc": "Durch den Zugriff auf und die Nutzung dieser Website stimmst du zu, die folgenden Nutzungsbedingungen einzuhalten und gebunden zu sein. Wenn du mit diesen Bedingungen nicht einverstanden bist, bitten wir dich, von der Nutzung dieser Website abzusehen.",
    "intellectualProperty": "2. Geistiges Eigentum",
    "intellectualPropertyDesc": "Alle Inhalte und Materialien, die auf dieser Seite verfügbar sind, einschließlich, aber nicht beschränkt auf Texte, Grafiken, Logos, Bilder und Software, sind Eigentum von Morgan Mathien und durch die geltenden Urheberrechts- und Markenrechtsgesetze geschützt.",
    "userConduct": "3. Nutzerverhalten",
    "userConductDesc1": "Du stimmst zu, dich nicht an Verhaltensweisen zu beteiligen, die die Funktionalität oder Sicherheit der Website stören oder beeinträchtigen könnten. Die unbefugte Nutzung dieser Website kann zu Schadensersatzansprüchen und/oder strafrechtlichen Konsequenzen führen.",
    "userConductDesc2": "Darüber hinaus stimmst du zu, nicht:",
    "conductRule1": "Die Website für illegale Zwecke oder in Verletzung dieser Bedingungen zu nutzen",
    "conductRule2": "Zu versuchen, unbefugten Zugriff auf Teile der Website zu erlangen",
    "conductRule3": "Die Operation der Website oder die Server oder Netzwerke, die zur Bereitstellung der Website verwendet werden, zu stören oder zu unterbrechen",
    "conductRule4": "Sich als eine andere Person oder Entität auszugeben oder fälschlicherweise anzugeben oder anderweitig deine Zugehörigkeit zu einer Person oder Entität falsch darzustellen",
    "limitationOfLiability": "4. Haftungsbeschränkung",
    "limitationLiabilityDesc1": "Morgan Mathien haftet nicht für Schäden, direkt oder indirekt, die aus der Nutzung dieser Website entstehen. Dies schließt, ist aber nicht beschränkt auf, jeglichen Daten- oder Gewinnverlust ein.",
    "limitationLiabilityDesc2": "Wir bemühen uns, genaue und aktuelle Informationen bereitzustellen, aber wir geben keine Zusicherungen oder Gewährleistungen jeglicher Art, ausdrücklich oder implizit, hinsichtlich der Vollständigkeit, Genauigkeit, Zuverlässigkeit, Eignung oder Verfügbarkeit der Website oder der Informationen, Produkte, Dienstleistungen oder zugehörigen Grafiken, die auf der Website für einen bestimmten Zweck enthalten sind.",
    "modificationsToTerms": "5. Änderungen der Bedingungen",
    "modificationsTermsDesc": "Morgan Mathien behält sich das Recht vor, diese Nutzungsbedingungen jederzeit ohne vorherige Ankündigung zu überarbeiten. Durch die Nutzung dieser Website erklärst du dich bereit, an die aktuelle Version dieser Nutzungsbedingungen gebunden zu sein.",
    "governingLaw": "6. Anwendbares Recht",
    "governingLawDesc": "Diese Bedingungen und Konditionen unterliegen dem Recht der Gerichtsbarkeit, in der Morgan Mathien ansässig ist, und du unterwirfst dich unwiderruflich der ausschließlichen Gerichtsbarkeit der Gerichte in diesem Staat oder Ort.",
    "contactInformation": "7. Kontaktinformation",
    "contactInfoDesc": "Wenn du Fragen zu diesen Nutzungsbedingungen hast, kontaktiere uns bitte unter:",

    // Privacy Policy
    "privacyPolicyIntro": "Bei Morgan Mathien setzen wir uns dafür ein, deine Privatsphäre zu schützen und die Sicherheit deiner persönlichen Informationen zu gewährleisten. Diese Datenschutzrichtlinie umreißt unsere Praktiken in Bezug auf die Erhebung, Verwendung und Offenlegung deiner Daten, wenn du unsere Website und Dienstleistungen nutzt.",
    "informationCollection": "1. Erhebung von Informationen",
    "informationCollectionDesc": "Wir erheben persönliche Informationen, wie Namen und E-Mail-Adressen, die freiwillig von Nutzern bereitgestellt werden. Wir können auch nicht-personenbezogene Informationen erheben, wie IP-Adressen und Browser-Typ.",
    "additionalInfoCollected": "Zusätzlich können wir erheben:",
    "contactInfo": "Kontaktinformationen (Telefonnummer, Adresse)",
    "travelPreferences": "Reisepräferenzen und -verlauf",
    "paymentInfo": "Zahlungsinformationen (sicher über Drittanbieter-Zahlungsdienstleister verarbeitet)",
    "customerServiceInfo": "Informationen, die du in Kundenservice-Interaktionen bereitstellst",
    "useOfInformation": "2. Verwendung von Informationen",
    "useOfInfoDesc": "Persönliche Informationen werden verwendet, um den Nutzern die angeforderten Informationen oder Dienstleistungen bereitzustellen. Nicht-personenbezogene Informationen werden für statistische Analysen verwendet, um den Inhalt und die Funktionalität der Website zu verbessern.",
    "additionalUseOfInfo": "Wir können deine Informationen auch verwenden, um:",
    "personalizeExperience": "Deine Erfahrung zu personalisieren und maßgeschneiderte Reiseempfehlungen bereitzustellen",
    "processTransactions": "Transaktionen zu verarbeiten und verwandte Informationen zu senden",
    "sendEmails": "Periodische E-Mails bezüglich deiner Buchungen, unserer Dienstleistungen oder anderer Informationen zu senden",
    "respondInquiries": "Auf deine Anfragen, Fragen und/oder anderen Anforderungen zu antworten",
    "improveWebsite": "Unsere Website und Dienstleistungen basierend auf Nutzerfeedback und Verhalten zu verbessern",

    "dataSecurity": "3. Datensicherheit",
    "dataSecurityDesc": "Wir implementieren angemessene Sicherheitsmaßnahmen, um unbefugten Zugriff auf oder unbefugte Änderungen, Offenlegungen oder Zerstörungen persönlicher Informationen zu schützen.",
    "securityMeasures": "Unsere Sicherheitsmaßnahmen umfassen:",
    "encryption": "Verwendung von Verschlüsselung für die Übertragung sensibler Daten",
    "securityAudits": "Regelmäßige Sicherheitsüberprüfungen und Schwachstellenbewertungen",
    "restrictedAccess": "Eingeschränkter Zugriff auf persönliche Informationen für Mitarbeiter, die dies zur Erfüllung ihrer Arbeitsaufgaben benötigen",
    "employeeTraining": "Mitarbeiterschulungen zu Datenschutz- und Sicherheitspraktiken",

    "thirdPartyDisclosure": "4. Offenlegung an Dritte",
    "thirdPartyDisclosureDesc": "Wir verkaufen, handeln oder übertragen keine persönlichen Informationen an externe Parteien. Dies schließt nicht vertrauenswürdige Drittanbieter ein, die uns bei der Betriebsführung unserer Website oder bei der Durchführung unseres Geschäfts unterstützen, vorausgesetzt, sie stimmen zu, diese Informationen vertraulich zu behandeln.",
    "infoSharing": "Wir können Informationen mit:",
    "serviceProviders": "Dienstleistern (z.B. Hosting-Dienste, Zahlungsdienstleister)",
    "travelPartners": "Reisepartnern, die zur Erfüllung deiner Buchungen notwendig sind",
    "legalAuthorities": "Rechtsbehörden teilen, wenn dies gesetzlich erforderlich ist oder um unsere Rechte zu schützen",

    "cookies": "5. Cookies",
    "cookiesDesc": "Wir können Cookies verwenden, um die Benutzererfahrung zu verbessern. Nutzer können wählen, ihre Webbrowser so einzustellen, dass Cookies abgelehnt oder gewarnt werden, wenn Cookies gesendet werden.",
    "cookiesUsage": "Wir verwenden sowohl Sitzungscookies (die ablaufen, sobald du deinen Browser schließt) als auch persistente Cookies (die für einen bestimmten Zeitraum auf deinem Gerät verbleiben oder bis du sie löscht). Sie helfen uns:",
    "rememberPreferences": "Deine Präferenzen und Einstellungen zu merken",
    "understandUsage": "Zu verstehen, wie du unsere Website nutzt",
    "improveServices": "Unsere Dienstleistungen und Benutzererfahrung zu verbessern",
    "providePersonalizedContent": "Personalisierte Inhalte und Empfehlungen bereitzustellen",

    "gdprCompliance": "6. DSGVO-Compliance",
    "gdprComplianceDesc": "Wir halten die Grundsätze der Datenschutz-Grundverordnung (DSGVO) ein. Nutzer in der Europäischen Union haben das Recht, auf ihre persönlichen Informationen zuzugreifen, sie zu korrigieren und zu löschen.",
    "gdprRights": "Zusätzliche Rechte gemäß DSGVO umfassen:",
    "rightToRestrict": "Das Recht, die Verarbeitung deiner personenbezogenen Daten einzuschränken",
    "rightToDataPortability": "Das Recht auf Datenübertragbarkeit",
    "rightToObject": "Das Recht, der Verarbeitung deiner personenbezogenen Daten zu widersprechen",
    "rightToWithdrawConsent": "Das Recht, die Einwilligung jederzeit zu widerrufen",

    "childrenPrivacy": "7. Datenschutz von Kindern",
    "childrenPrivacyDesc": "Unsere Dienstleistungen sind nicht für Kinder unter 13 Jahren bestimmt. Wir sammeln nicht absichtlich persönliche Informationen von Kindern unter 13 Jahren. Wenn du ein Elternteil oder Vormund bist und glaubst, dass wir Informationen über ein Kind gesammelt haben, kontaktiere uns bitte umgehend.",

    "changesPrivacyPolicy": "8. Änderungen dieser Datenschutzrichtlinie",
    "changesPrivacyPolicyDesc": "Wir können unsere Datenschutzrichtlinie von Zeit zu Zeit aktualisieren. Wir werden dich über Änderungen informieren, indem wir die neue Datenschutzrichtlinie auf dieser Seite veröffentlichen und das Datum 'Zuletzt aktualisiert' am Ende dieser Seite aktualisieren. Es wird empfohlen, diese Datenschutzrichtlinie regelmäßig auf Änderungen zu überprüfen.",

    "contactUs": "9. Kontaktiere uns",
    "contactUsDesc": "Wenn du Fragen zu dieser Datenschutzrichtlinie hast, kontaktiere uns bitte unter:",

    // Common
    "address": "123 Reise Straße, Wanderlust Stadt, 12345, Vereinigte Staaten",
    "phone": "Telefon",
    "mobile": "Mobil",
    "lastUpdated": "Zuletzt aktualisiert:",

    // Travel Map
    "placesIveBeen": "Orte, die ich besucht habe",

    // Booking Page
    "bookYourMeeting": "Buche dein Meeting",

    // Error Messages
    "pageNotFound": "Seite Nicht Gefunden",
    "pageNotFoundMessage": "Entschuldigung, die Seite, die du suchst, existiert nicht.",
    "returnHome": "Zurück zur Startseite",

    // Auth Modal
    "login": "Anmelden",
    "signUp": "Registrieren",
    "password": "Passwort",
    "signInWithGoogle": "Mit Google anmelden",
    "alreadyHaveAccount": "Hast du bereits ein Konto?",
    "dontHaveAccount": "Hast du kein Konto?",

    // Profile
    "profile": "Profil",
    "editProfile": "Profil bearbeiten",
    "saveChanges": "Änderungen speichern",
    "logOut": "Abmelden",

    // Misc
    "loading": "Laden...",
    "errorOccurred": "Ein Fehler ist aufgetreten. Bitte versuche es erneut.",
    "successfullyUpdated": "Erfolgreich aktualisiert.",
    "confirmAction": "Bist du sicher, dass du diese Aktion ausführen möchtest?",
    "yes": "Ja",
    "no": "Nein",
    "cancel": "Abbrechen",
    "submit": "Absenden",
    "readMore": "Mehr Lesen",
    "showLess": "Weniger Anzeigen",
    "viewAll": "Alle Anzeigen",
    "backToTop": "Zurück nach oben",

    // LinkedIn Card
    "linkedinCardTitle": "Berater für Geschäftsentwicklung | Abenteurer | Weltenbummler",
    "linkedinCardLocation": "Bali, Indonesien",
    "linkedinCardDescription": "Leidenschaftlich daran interessiert, das Unternehmenswachstum voranzutreiben und globale Auswirkungen zu fördern. Mit einem vielfältigen Hintergrund in der Reiseverwaltung, Veranstaltungskoordination, Kundenservice und Geschäftsentwicklung habe ich ein einzigartiges Skillset entwickelt, das es mir ermöglicht, komplexe Herausforderungen zu meistern und gleichzeitig Chancen für Expansion und positive Veränderungen zu erkennen.",
    "linkedinCardCompany": "Freiberuflicher Berater für Geschäftsentwicklung",
    "linkedinCardEducation": "SUNY Buffalo State College",
    "viewLinkedInProfile": "LinkedIn-Profil anzeigen",
"placesTitle": "Orte, die ich besucht habe",

};