import React from 'react';
import Hero from './Hero';
import Services from './Services';
import About from './About';
import Testimonials from './Testimonials';
import FAQ from './FAQ';
import Contact from './Contact';
import Packages from './Packages';
import TravelMap from './TravelMap';

const Home = () => {
  return (
    <div>
      <Hero />
      <div id="services"><Services /></div>
      <div id="about"><About /></div>
      <div id="packages"><Packages /></div>
      <TravelMap />
      <Testimonials />
      <FAQ />
      <div id="contact"><Contact /></div>
    </div>
  );
};

export default Home;