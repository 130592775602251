import React, { useRef, useEffect } from 'react';
import { useAppContext } from '../../AppContext';

const Hero = () => {
  const videoRef = useRef(null);
  const { t } = useAppContext();

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play().catch(error => {
        console.log("Autoplay was prevented:", error);
      });
    }
  }, []);

  return (
    <section className="relative h-screen flex items-end justify-center overflow-hidden">
      <video
        ref={videoRef}
        autoPlay
        loop
        muted
        playsInline
        className="absolute z-0 w-full h-full object-cover"
      >
        <source src="/images/video.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      
      <div className="absolute inset-0 bg-black opacity-50 z-5"></div>
      
      <div className="relative z-10 text-center text-white pb-20 w-full max-w-4xl px-4">
        <img src='/images/logomorgan.png' alt="Morgan Mathien Logo" className="mx-auto mb-8 w-64" />
        <h1 className="text-4xl md:text-5xl font-bold mb-6">{t("elevateYourBusinessGrowth")}</h1>
        <p className="text-xl mb-10 max-w-2xl mx-auto">
          {t("heroDescription")}
        </p>
        <a 
          href="#contact"  
          className="bg-primary hover:bg-primary-dark text-white font-bold py-3 px-8 rounded-full transition duration-300 text-lg inline-block"
        >
          {t("startYourJourney")}
        </a>
      </div>
    </section>
  );
};

export default Hero;