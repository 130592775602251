// src/translations/es.js
export default {
    // Header
    "packages": "Paquetes",
    "services": "Servicios",
    "about": "Acerca de",
    "contact": "Contacto",
    "letsTalk": "Hablemos",
  
    // Hero
    "elevateYourBusinessGrowth": "Eleva el Crecimiento de tu Negocio",
    "heroDescription": "Experimenta el desarrollo estratégico con soluciones personalizadas y una orientación a la medida. Transformemos los desafíos en oportunidades.",
    "startYourJourney": "Comienza tu Viaje",
  
    // Services
    "elevateYourBusinessWithExpertSolutions": "Eleva tu Negocio con Soluciones Expertas",
    "servicesDescription": "Hola, soy Morgan, tu consultora dedicada al desarrollo de negocios. Con una gran experiencia global y un conjunto diverso de habilidades, estoy aquí para ayudar a tu negocio a prosperar en el panorama competitivo actual. Mis servicios están diseñados para optimizar tus operaciones, descubrir nuevas oportunidades e impulsar un crecimiento sostenible.",
    "businessGrowthStrategy": "Estrategia de Crecimiento Empresarial",
    "businessGrowthStrategyDesc": "Desarrollar estrategias personalizadas para impulsar tu negocio y lograr un crecimiento sostenible.",
    "customerServiceSupport": "Soporte de Servicio al Cliente",
    "customerServiceSupportDesc": "Mejorar tu experiencia del cliente con soporte experto y gestión de relaciones.",
    "administrativeSupport": "Soporte Administrativo",
    "administrativeSupportDesc": "Optimizar tus operaciones con soluciones administrativas y organizativas eficientes.",
    "internationalBusinessDevelopment": "Desarrollo de Negocios Internacionales",
    "internationalBusinessDevelopmentDesc": "Aprovechar la experiencia global para expandir tu negocio a través de las fronteras.",
    "salesDevelopment": "Desarrollo de Ventas",
    "salesDevelopmentDesc": "Implementar estrategias y procesos de ventas efectivos para aumentar tus ingresos.",
    "marketResearch": "Investigación de Mercado",
    "marketResearchDesc": "Obtener valiosos insights con investigación y análisis de mercado exhaustivos.",
  
    // About
    "aboutMorgan": "Acerca de mí",
    "aboutDescription": "Hola, soy Morgan, y me apasiona impulsar el crecimiento empresarial y fomentar el impacto global. Con una experiencia diversa que abarca la gestión de viajes, la coordinación de eventos, el servicio al cliente y el desarrollo de negocios, he perfeccionado un conjunto único de habilidades que me permite navegar por desafíos complejos mientras identifico oportunidades de expansión y cambio positivo.",
    "myMission": "Mi Misión",
    "missionDescription": "Me asocio con marcas de bienestar y empresas de aventura para impulsar el crecimiento, fomentar el impacto global e inspirar cambios positivos. Al aprovechar mi experiencia en desarrollo de negocios, salud, nutrición y aventura, elaboro estrategias personalizadas que mejoran la visibilidad de la marca, construyen asociaciones auténticas y promueven el bienestar integral.",
    "ourCoreValues": "Nuestros Valores Fundamentales",
    "resultsDriven": "Orientados a Resultados",
    "resultsDrivenDesc": "Nos enfocamos en lograr resultados tangibles y medibles para tu negocio.",
    "innovativeSolutions": "Soluciones Innovadoras",
    "innovativeSolutionsDesc": "Aportamos ideas frescas y creativas para superar los desafíos de tu negocio.",
    "clientCentricApproach": "Enfoque Centrado en el Cliente",
    "clientCentricApproachDesc": "Tu éxito está en el corazón de todo lo que hacemos.",
    "globalPerspective": "Perspectiva Global",
    "globalPerspectiveDesc": "Aprovechamos la experiencia internacional para dar a tu negocio una ventaja competitiva.",
    "continuousImprovement": "Mejora Continua",
    "continuousImprovementDesc": "Evolucionamos constantemente nuestras estrategias para mantenernos a la vanguardia en el cambiante panorama empresarial.",
    "integrityTransparency": "Integridad y Transparencia",
    "integrityTransparencyDesc": "Creemos en la comunicación honesta y abierta y en las prácticas comerciales éticas.",
    "wellnessFocus": "Enfoque en el Bienestar",
    "wellnessFocusDesc": "Promovemos estilos de vida más saludables a través de nuestras estrategias comerciales.",
    "adventureSpirit": "Espíritu de Aventura",
    "adventureSpiritDesc": "Infundimos una mentalidad aventurera en el crecimiento empresarial.",
    "letsCreateImpactTogether": "Creemos Impacto Juntos",
    "impactDescription": "Ya sea que seas una marca de bienestar, una empresa de aventura o un negocio que busca hacer una diferencia global, ¡embarquémonos juntos en este viaje y creemos un crecimiento extraordinario y un cambio positivo!",
  
    // Packages
    "ourServicePackages": "Nuestros Paquetes de Servicios",
    "chooseThePerfectPackage": "Elige el paquete perfecto para acelerar el crecimiento de tu negocio",
    "hourlyConsultation": "Consultoría por Hora",
    "hourlyConsultationDesc": "Asesoramiento y soporte flexible para el desarrollo de negocios bajo demanda.",
    "projectBasedSolutions": "Soluciones Basadas en Proyectos",
    "projectBasedSolutionsDesc": "Apoyo integral para iniciativas específicas de crecimiento empresarial.",
    "retainerServices": "Servicios de Retención",
    "retainerServicesDesc": "Asociación estratégica continua para la mejora y el crecimiento continuo del negocio.",
    "oneOnOneExpertConsultation": "Consulta experta uno a uno",
    "tailoredAdvice": "Asesoramiento personalizado para las necesidades de tu negocio",
    "flexibleScheduling": "Programación flexible",
    "noLongTermCommitment": "Sin compromiso a largo plazo",
    "inDepthBusinessAnalysis": "Análisis de negocio en profundidad",
    "customStrategyDevelopment": "Desarrollo de estrategia personalizada",
    "implementationSupport": "Soporte de implementación",
    "projectManagementAndReporting": "Gestión de proyectos e informes",
    "dedicatedBusinessDevelopmentSupport": "Soporte dedicado al desarrollo de negocios",
    "regularStrategySessions": "Sesiones de estrategia regulares",
    "continuousImprovementInitiatives": "Iniciativas de mejora continua",
    "priorityAccessToOurExpertise": "Acceso prioritario a nuestra experiencia",
    "startingAt25PerHour": "Desde $25/hora",
    "customQuoteBasedOnProjectScope": "Presupuesto personalizado según el alcance del proyecto",
    "startingAt500PerMonth": "Desde $500/mes",
    "getStarted": "Comenzar",
  
    // Testimonials
    "clientTestimonials": "Testimonios de Clientes",
    "patrickTestimonial": "Trabajar con Morgan ha sido un cambio de juego para mi negocio. Es increíblemente organizada, proactiva y siempre trae ideas frescas a la mesa. Ya sea optimizando procesos, gestionando la comunicación con los clientes o ayudando con la estrategia, Morgan maneja todo con profesionalidad y facilidad. Ha sido un gran activo y no puedo recomendarla lo suficiente.",
    "eileenTestimonial": "Estoy extremadamente satisfecha con los increíbles servicios de viajes familiares de Morgan. Su cuidadosa planificación y talento para crear experiencias inolvidables superaron nuestras expectativas. Gracias a ella, nuestro viaje se convirtió en un recuerdo familiar verdaderamente especial.",
    "lesleeTestimonial": "Morgan ha sido una salvación absoluta ayudándome a hacer crecer mis ventas. Su capacidad para organizar, rastrear leads y hacer seguimiento a clientes potenciales ha quitado mucho de mi plato. Siempre está dos pasos adelante, asegurándose de que nada se caiga por las grietas. Sus perspectivas y apoyo han tenido un impacto real en mi negocio. No podría haber escalado mis esfuerzos de ventas sin ella, ¡y estoy muy agradecida por su ayuda!",
  
    // FAQ
    "frequentlyAskedQuestions": "Preguntas Frecuentes",
    "howCanYourServicesHelp": "¿Cómo pueden ayudar tus servicios a crecer mi negocio?",
    "howCanYourServicesHelpAnswer": "Mis servicios están diseñados para identificar oportunidades de crecimiento, optimizar operaciones e implementar estrategias efectivas. Trabajaré estrechamente contigo para entender tus objetivos de negocio y desarrollar soluciones a medida para alcanzarlos, ya sea a través de investigación de mercado, desarrollo de ventas o mejoras en la eficiencia operativa.",
    "whatTypesOfBusinesses": "¿Con qué tipos de negocios trabajas?",
    "whatTypesOfBusinessesAnswer": "Trabajo con una amplia gama de negocios, desde startups hasta empresas establecidas, en diversos sectores. Mi experiencia diversa me permite adaptar mi enfoque para satisfacer las necesidades únicas de cada cliente, independientemente de su tamaño o sector.",
    "howLongToSeeResults": "¿Cuánto tiempo suele tardar en verse resultados?",
    "howLongToSeeResultsAnswer": "El tiempo para ver resultados puede variar dependiendo de los objetivos específicos y los desafíos de tu negocio. Algunas mejoras, como eficiencias operativas, pueden verse relativamente rápido. Para iniciativas estratégicas más grandes, puede tomar varios meses ver resultados significativos. Trabajaré contigo para establecer expectativas realistas e hitos.",
    "howDoYouCharge": "¿Cómo cobras por tus servicios?",
    "howDoYouChargeAnswer": "Mi estructura de precios es flexible y depende del alcance y la duración del proyecto. Ofrezco opciones tanto basadas en proyectos como de retención. Discutiremos tus necesidades en detalle y te proporcionaré un presupuesto transparente adaptado a tus requisitos específicos.",
  
    // Contact
    "getInTouch": "Contáctame",
    "name": "Nombre",
    "email": "Correo Electrónico",
    "message": "Mensaje",
    "sendMessage": "Enviar Mensaje",
    "sending": "Enviando...",
    "thankYouMessage": "Gracias por tu mensaje. ¡Me pondré en contacto contigo pronto!",
    "failedToSendMessage": "No se pudo enviar el mensaje",
    "errorOccurred": "Ocurrió un error al enviar tu mensaje. Por favor, inténtalo de nuevo.",
  
    // Footer
    "legal": "Legal",
    "privacyPolicy": "Política de Privacidad",
    "termsOfUse": "Términos de Uso",
    "cookiePolicy": "Política de Cookies",
    "contactInfo": "Información de Contacto",
    "copyrightNotice": "© 2024 Morgan Mathien - Devolviendo valor. Todos los derechos reservados.",

    // Cookie Policy
    "cookiePolicyIntro": "Esta Política de Cookies explica cómo Morgan Mathien utiliza cookies y tecnologías similares para reconocerte cuando visitas nuestro sitio web. Explica qué son estas tecnologías y por qué las usamos, así como tus derechos para controlar nuestro uso de ellas.",
    "whatAreCookies": "¿Qué son las cookies?",
    "cookiesExplanation": "Las cookies son pequeños archivos de datos que se colocan en tu computadora o dispositivo móvil cuando visitas un sitio web. Los propietarios de sitios web utilizan ampliamente las cookies para hacer que sus sitios funcionen, o para que funcionen de manera más eficiente, así como para proporcionar información de informes.",
    "firstThirdPartyCookies": "Las cookies establecidas por el propietario del sitio web (en este caso, Morgan Mathien) se llaman 'cookies de primera parte'. Las cookies establecidas por partes distintas al propietario del sitio web se llaman 'cookies de terceros'. Las cookies de terceros permiten que se proporcionen características o funcionalidades de terceros en o a través del sitio web.",
    "whyWeUseCookies": "¿Por qué usamos cookies?",
    "cookiesUsageExplanation": "Utilizamos cookies de primera y tercera parte por varias razones. Algunas cookies son necesarias por razones técnicas para que nuestro sitio web funcione, y nos referimos a estas como cookies 'esenciales' o 'estrictamente necesarias'. Otras cookies también nos permiten rastrear y dirigir los intereses de nuestros usuarios para mejorar la experiencia en nuestro sitio web. Terceros sirven cookies a través de nuestro sitio web para publicidad, análisis y otros propósitos.",
    "typesOfCookiesWeUse": "Tipos de cookies que usamos",
    "cookieTypesIntro": "Los tipos específicos de cookies de primera y tercera parte que se sirven a través de nuestro sitio web y los propósitos que cumplen se describen a continuación:",
    "essentialCookies": "Cookies esenciales del sitio web:",
    "essentialCookiesDesc": "Estas cookies son estrictamente necesarias para proporcionarte los servicios disponibles a través de nuestro sitio web y para usar algunas de sus características.",
    "performanceCookies": "Cookies de rendimiento y funcionalidad:",
    "performanceCookiesDesc": "Estas cookies se utilizan para mejorar el rendimiento y la funcionalidad de nuestro sitio web, pero no son esenciales para su uso.",
    "analyticsCookies": "Cookies de análisis y personalización:",
    "analyticsCookiesDesc": "Estas cookies recopilan información que se utiliza, ya sea en forma agregada, para ayudarnos a entender cómo se está utilizando nuestro sitio web o cuán efectivas son nuestras campañas de marketing, o para ayudarnos a personalizar nuestro sitio web para ti.",
    "advertisingCookies": "Cookies de publicidad:",
    "advertisingCookiesDesc": "Estas cookies se utilizan para hacer que los mensajes publicitarios sean más relevantes para ti.",
    "socialNetworkingCookies": "Cookies de redes sociales:",
    "socialNetworkingCookiesDesc": "Estas cookies se utilizan para permitirte compartir páginas y contenido que encuentras interesantes en nuestro sitio web a través de redes sociales de terceros y otros sitios web.",
    "howToControlCookies": "¿Cómo puedes controlar las cookies?",
    "cookieControlExplanation": "Tienes el derecho de decidir si aceptar o rechazar cookies. Puedes ejercer tus preferencias de cookies haciendo clic en los enlaces de exclusión correspondientes proporcionados en el banner de cookies.",
    "browserControlExplanation": "También puedes configurar o modificar los controles de tu navegador web para aceptar o rechazar cookies.",
    "updatesPolicy": "¿Con qué frecuencia actualizaremos esta Política de Cookies?",
    "policyUpdateExplanation": "Podemos actualizar esta Política de Cookies de vez en cuando para reflejar, por ejemplo, cambios en las cookies que usamos o por otras razones operativas, legales o regulatorias.",
    "moreInfo": "¿Dónde puedes obtener más información?",
    "contactForMoreInfo": "Si tienes alguna pregunta sobre nuestro uso de cookies u otras tecnologías, envíanos un correo electrónico a:",

    // Terms of Use
    "acceptanceOfTerms": "1. Aceptación de Términos",
    "acceptanceTermsDesc": "Al acceder y utilizar este sitio web, aceptas cumplir y estar obligado por los siguientes términos y condiciones de uso. Si no estás de acuerdo con estos términos, por favor abstente de utilizar este sitio web.",
    "intellectualProperty": "2. Propiedad Intelectual",
    "intellectualPropertyDesc": "Todo el contenido y los materiales disponibles en este sitio, incluidos pero no limitados a texto, gráficos, logotipos, imágenes y software, son propiedad de Morgan Mathien y están protegidos por las leyes de derechos de autor y marcas comerciales aplicables.",
    "userConduct": "3. Conducta del Usuario",
    "userConductDesc1": "Aceptas no participar en ninguna conducta que pueda interrumpir o interferir con la funcionalidad o seguridad del sitio web. El uso no autorizado de este sitio web puede dar lugar a una reclamación por daños y/o ser un delito penal.",
    "userConductDesc2": "Además, aceptas no:",
    "conductRule1": "Usar el sitio web para cualquier propósito ilegal o en violación de estos términos",
    "conductRule2": "Intentar obtener acceso no autorizado a cualquier parte del sitio web",
    "conductRule3": "Interferir con o interrumpir la operación del sitio web o los servidores o redes utilizados para hacer disponible el sitio web",
    "conductRule4": "Hacerse pasar por cualquier persona o entidad, o declarar falsamente o de otro modo tergiversar tu afiliación con una persona o entidad",
    "limitationOfLiability": "4. Limitación de Responsabilidad",
    "limitationLiabilityDesc1": "Morgan Mathien no es responsable de ningún daño, directo o indirecto, que surja del uso de este sitio web. Esto incluye, pero no se limita a, cualquier pérdida de datos o ganancias.",
    "limitationLiabilityDesc2": "Nos esforzamos por proporcionar información precisa y actualizada, pero no hacemos representaciones ni garantías de ningún tipo, expresas o implícitas, sobre la integridad, precisión, confiabilidad, idoneidad o disponibilidad con respecto al sitio web o la información, productos, servicios o gráficos relacionados contenidos en el sitio web para cualquier propósito.",
    "modificationsToTerms": "5. Modificaciones a los Términos",
    "modificationsTermsDesc": "Morgan Mathien se reserva el derecho de revisar estos términos de uso en cualquier momento sin previo aviso. Al utilizar este sitio web, aceptas estar obligado por la versión actual de estos términos de servicio.",
    "governingLaw": "6. Ley Aplicable",
    "governingLawDesc": "Estos términos y condiciones se rigen y se interpretan de acuerdo con las leyes de la jurisdicción donde Morgan Mathien está establecido y tú te sometes irrevocablemente a la jurisdicción exclusiva de los tribunales en ese Estado o ubicación.",
    "contactInformation": "7. Información de Contacto",
    "contactInfoDesc": "Si tienes alguna pregunta sobre estos Términos de Uso, por favor contáctanos en:",

    // Privacy Policy
    "privacyPolicyIntro": "En Morgan Mathien, estamos comprometidos a proteger tu privacidad y asegurar la seguridad de tu información personal. Esta Política de Privacidad describe nuestras prácticas sobre la recopilación, uso y divulgación de tus datos cuando utilizas nuestro sitio web y servicios.",
    "informationCollection": "1. Recopilación de Información",
    "informationCollectionDesc": "Recopilamos información personal, como nombres y direcciones de correo electrónico, enviadas voluntariamente por los usuarios. También podemos recopilar información no personal, como direcciones IP y tipo de navegador.",
    "additionalInfoCollected": "Adicionalmente, podemos recopilar:",
    "contactInfo": "Información de contacto (número de teléfono, dirección)",
    "travelPreferences": "Preferencias y historial de viaje",
    "paymentInfo": "Información de pago (procesada de forma segura a través de procesadores de pago de terceros)",
    "customerServiceInfo": "Información que proporcionas en interacciones de servicio al cliente",
    "useOfInformation": "2. Uso de la Información",
    "useOfInfoDesc": "La información personal se utiliza para proporcionar a los usuarios la información o servicios solicitados. La información no personal se utiliza para análisis estadísticos, para mejorar el contenido y la funcionalidad del sitio web.",
    "additionalUseOfInfo": "También podemos utilizar tu información para:",
    "personalizeExperience": "Personalizar tu experiencia y proporcionar recomendaciones de viaje personalizadas",
    "processTransactions": "Procesar transacciones y enviar información relacionada",
    "sendEmails": "Enviar correos electrónicos periódicos sobre tus reservas, nuestros servicios u otra información",
    "respondInquiries": "Responder a tus consultas, preguntas y/o solicitudes",
    "improveWebsite": "Mejorar nuestro sitio web y servicios en función de los comentarios y comportamientos de los usuarios",

    "dataSecurity": "3. Seguridad de los Datos",
    "dataSecurityDesc": "Implementamos medidas de seguridad razonables para proteger contra el acceso no autorizado o la alteración, divulgación o destrucción no autorizada de información personal.",
    "securityMeasures": "Nuestras medidas de seguridad incluyen:",
    "encryption": "Uso de cifrado para la transmisión de datos sensibles",
    "securityAudits": "Auditorías de seguridad regulares y evaluaciones de vulnerabilidad",
    "restrictedAccess": "Acceso restringido a la información personal para empleados que lo necesiten para realizar sus funciones laborales",
    "employeeTraining": "Capacitación de empleados sobre prácticas de privacidad y seguridad",

    "thirdPartyDisclosure": "4. Divulgación a Terceros",
    "thirdPartyDisclosureDesc": "No vendemos, comerciamos ni transferimos de otro modo información personal a partes externas. Esto no incluye a terceros de confianza que nos ayudan a operar nuestro sitio web o llevar a cabo nuestro negocio, siempre que acepten mantener esta información confidencial.",
    "infoSharing": "Podemos compartir información con:",
    "serviceProviders": "Proveedores de servicios (por ejemplo, servicios de alojamiento, procesadores de pago)",
    "travelPartners": "Socios de viaje necesarios para cumplir con tus reservas",
    "legalAuthorities": "Autoridades legales si lo requiere la ley o para proteger nuestros derechos",

    "cookies": "5. Cookies",
    "cookiesDesc": "Podemos utilizar cookies para mejorar la experiencia del usuario. Los usuarios pueden optar por configurar sus navegadores web para rechazar cookies o alertarlos cuando se envían cookies.",
    "cookiesUsage": "Utilizamos tanto cookies de sesión (que expiran una vez que cierras tu navegador) como cookies persistentes (que permanecen en tu dispositivo por un período establecido o hasta que las eliminas). Ayudan a:",
    "rememberPreferences": "Recordar tus preferencias y configuraciones",
    "understandUsage": "Entender cómo utilizas nuestro sitio web",
    "improveServices": "Mejorar nuestros servicios y la experiencia del usuario",
    "providePersonalizedContent": "Proporcionar contenido y recomendaciones personalizadas",

    "gdprCompliance": "6. Cumplimiento del GDPR",
    "gdprComplianceDesc": "Cumplimos con los principios del Reglamento General de Protección de Datos (GDPR). Los usuarios en la Unión Europea tienen derecho a acceder, corregir y eliminar su información personal.",
    "gdprRights": "Derechos adicionales bajo el GDPR incluyen:",
    "rightToRestrict": "El derecho a restringir el procesamiento de tus datos personales",
    "rightToDataPortability": "El derecho a la portabilidad de los datos",
    "rightToObject": "El derecho a oponerte al procesamiento de tus datos personales",
    "rightToWithdrawConsent": "El derecho a retirar el consentimiento en cualquier momento",

    "childrenPrivacy": "7. Privacidad de los Niños",
    "childrenPrivacyDesc": "Nuestros servicios no están destinados a niños menores de 13 años. No recopilamos intencionalmente información personal de niños menores de 13 años. Si eres un padre o tutor y crees que podemos haber recopilado información sobre un niño, contáctanos de inmediato.",

    "changesPrivacyPolicy": "8. Cambios a Esta Política de Privacidad",
    "changesPrivacyPolicyDesc": "Podemos actualizar nuestra Política de Privacidad de vez en cuando. Te notificaremos sobre cualquier cambio publicando la nueva Política de Privacidad en esta página y actualizando la fecha de 'Última actualización' en la parte inferior de esta página. Se te aconseja revisar periódicamente esta Política de Privacidad para cualquier cambio.",

    "contactUs": "9. Contáctanos",
    "contactUsDesc": "Si tienes alguna pregunta sobre esta Política de Privacidad, por favor contáctanos en:",

    // Common
    "address": "123 Calle de Viaje, Ciudad Wanderlust, 12345, Estados Unidos",
    "phone": "Teléfono",
    "mobile": "Móvil",
    "lastUpdated": "Última actualización:",

    // Travel Map
    "placesIveBeen": "Lugares que He Visitado",

    // Booking Page
    "bookYourMeeting": "Reserva Tu Reunión",

    // Error Messages
    "pageNotFound": "Página No Encontrada",
    "pageNotFoundMessage": "Lo sentimos, la página que estás buscando no existe.",
    "returnHome": "Volver a Inicio",

    // Auth Modal
    "login": "Iniciar Sesión",
    "signUp": "Registrarse",
    "password": "Contraseña",
    "signInWithGoogle": "Iniciar sesión con Google",
    "alreadyHaveAccount": "¿Ya tienes una cuenta?",
    "dontHaveAccount": "¿No tienes una cuenta?",

    // Profile
    "profile": "Perfil",
    "editProfile": "Editar Perfil",
    "saveChanges": "Guardar Cambios",
    "logOut": "Cerrar Sesión",

    // Misc
    "loading": "Cargando...",
    "errorOccurred": "Ocurrió un error. Por favor, intenta de nuevo.",
    "successfullyUpdated": "Actualizado con éxito.",
    "confirmAction": "¿Estás seguro de que quieres realizar esta acción?",
    "yes": "Sí",
    "no": "No",
    "cancel": "Cancelar",
    "submit": "Enviar",
    "readMore": "Leer Más",
    "showLess": "Mostrar Menos",
    "viewAll": "Ver Todo",
    "backToTop": "Volver Arriba",

    // LinkedIn Card
    "linkedinCardTitle": "Consultor de Desarrollo de Negocios | Aventurero | Viajero del Mundo",
    "linkedinCardLocation": "Bali, Indonesia",
    "linkedinCardDescription": "Apasionado por impulsar el crecimiento empresarial y fomentar un impacto global. Con una trayectoria diversa que abarca la gestión de viajes, la coordinación de eventos, el servicio al cliente y el desarrollo de negocios, he perfeccionado un conjunto de habilidades único que me permite navegar por desafíos complejos mientras identifico oportunidades de expansión y cambio positivo.",
    "linkedinCardCompany": "Consultor de Desarrollo de Negocios Freelance",
    "linkedinCardEducation": "SUNY Buffalo State College",
    "viewLinkedInProfile": "Ver Perfil de LinkedIn",
    "placesTitle": "Lugares que he visitado",

  };