import React from 'react';
import { TrendingUp, Users, FileText, Globe, Briefcase, Search } from 'lucide-react';
import { useAppContext } from '../../AppContext';

const ServiceCard = ({ icon: Icon, title, description }) => (
  <div className="bg-white p-6 rounded-lg shadow-md text-center transition duration-300 hover:shadow-lg">
    <Icon size={48} className="text-accent mx-auto mb-4" />
    <h3 className="text-xl font-bold mb-2 text-primary">{title}</h3>
    <p className="text-text-light">{description}</p>
  </div>
);

const Services = () => {
  const { t } = useAppContext();

  const services = [
    { icon: TrendingUp, title: t("businessGrowthStrategy"), description: t("businessGrowthStrategyDesc") },
    { icon: Users, title: t("customerServiceSupport"), description: t("customerServiceSupportDesc") },
    { icon: FileText, title: t("administrativeSupport"), description: t("administrativeSupportDesc") },
    { icon: Globe, title: t("internationalBusinessDevelopment"), description: t("internationalBusinessDevelopmentDesc") },
    { icon: Briefcase, title: t("salesDevelopment"), description: t("salesDevelopmentDesc") },
    { icon: Search, title: t("marketResearch"), description: t("marketResearchDesc") },
  ];

  return (
    <section id="services" className="py-20 bg-background">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-bold text-center mb-12 text-primary">{t("elevateYourBusinessWithExpertSolutions")}</h2>
        <p className="text-xl mb-12 text-center text-text-light">
          {t("servicesDescription")}
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {services.map((service, index) => (
            <ServiceCard key={index} {...service} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services;