import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Menu, X, User, LogOut, LogIn, Briefcase, Info, Map, Phone, Globe, Package, Clock } from 'lucide-react';
import { useAppContext } from '../../AppContext';
import AuthModal from './AuthModal';
import { auth } from '../../firebase';
import { signOut } from 'firebase/auth';
import useScrollToTop from '../../hooks/useScrollToTop';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);
  const [isLanguageMenuOpen, setIsLanguageMenuOpen] = useState(false);
  const { language, setLanguage, user, setUser, t } = useAppContext();
  const navigate = useNavigate();
  const location = useLocation();
  useScrollToTop();

  const languages = [
    { code: 'en', flag: '🇬🇧' },
    { code: 'es', flag: '🇪🇸' },
    { code: 'de', flag: '🇩🇪' },
  ];

  const menuItems = [
    { to: "packages", text: "packages", icon: Package },
    { to: "services", text: "services", icon: Briefcase },
    { to: "about", text: "about", icon: Info },
    { to: "contact", text: "contact", icon: Phone },
  ];

  const handleNavClick = (sectionId) => {
    setIsMenuOpen(false);
    if (location.pathname !== '/') {
      navigate('/');
      setTimeout(() => {
        const element = document.getElementById(sectionId);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }, 100);
    } else {
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  const handleLogoClick = (e) => {
    e.preventDefault();
    if (location.pathname !== '/') {
      navigate('/');
    } else {
      window.scrollTo(0, 0);
    }
  };

  const toggleLanguageMenu = () => {
    setIsLanguageMenuOpen(!isLanguageMenuOpen);
  };

  const handleLanguageChange = (langCode) => {
    setLanguage(langCode);
    setIsLanguageMenuOpen(false);
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      setUser(null);
      setIsMenuOpen(false);
      navigate('/');
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  return (
    <header className="bg-primary shadow-md fixed top-0 left-0 right-0 z-50">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center py-4">
          <Link to="/" onClick={handleLogoClick} className="flex items-center">
            <img 
              src="/images/logo.png" 
              alt="MorganMathien Logo" 
              className="h-8 w-auto"
            />
          </Link>
          <nav className="hidden md:flex items-center space-x-6">
            {menuItems.map((item) => (
              <button
                key={item.to}
                onClick={() => handleNavClick(item.to)}
                className="flex items-center text-white hover:text-accent transition duration-300"
              >
                <item.icon size={20} className="mr-1" />
                <span>{t(item.text)}</span>
              </button>
            ))}
            
            <Link 
              to="/book/time-based" 
              className="flex items-center bg-accent text-white px-4 py-2 rounded-full hover:bg-accent-light transition duration-300"
            >
              <Clock size={20} className="mr-2" />
              {t("letsTalk")}
            </Link>
            
            <div className="relative">
              <button onClick={toggleLanguageMenu} className="flex items-center text-white hover:text-accent transition duration-300">
                <span>{languages.find(lang => lang.code === language).flag}</span>
                <Globe size={20} className="ml-1" />
              </button>
              {isLanguageMenuOpen && (
                <div className="absolute right-0 mt-2 w-24 bg-white rounded-md shadow-lg">
                  {languages.map((lang) => (
                    <button
                      key={lang.code}
                      onClick={() => handleLanguageChange(lang.code)}
                      className="block w-full text-left px-4 py-2 text-sm text-primary hover:bg-background hover:text-accent transition duration-300"
                    >
                      {lang.flag} {lang.code.toUpperCase()}
                    </button>
                  ))}
                </div>
              )}
            </div>
            
            {user && (
              <Link to="/profile" className="flex items-center text-white hover:text-accent transition duration-300">
                <User size={20} className="mr-1" />
                <span className="text-sm">{user.displayName || user.email}</span>
              </Link>
            )}
            
          </nav>
          <div className="md:hidden flex items-center space-x-4">
            <Link 
              to="/book/time-based" 
              className="flex items-center bg-accent text-white px-3 py-1 rounded-full hover:bg-accent-light transition duration-300 text-sm"
            >
              <Clock size={16} className="mr-1" />
              {t("letsTalk")}
            </Link>
            <div className="relative">
              <button onClick={toggleLanguageMenu} className="flex items-center text-white hover:text-accent transition duration-300">
                <span>{languages.find(lang => lang.code === language).flag}</span>
                <Globe size={16} className="ml-1" />
              </button>
              {isLanguageMenuOpen && (
                <div className="absolute right-0 mt-2 w-24 bg-white rounded-md shadow-lg">
                  {languages.map((lang) => (
                    <button
                      key={lang.code}
                      onClick={() => handleLanguageChange(lang.code)}
                      className="block w-full text-left px-4 py-2 text-sm text-primary hover:bg-background hover:text-accent transition duration-300"
                    >
                      {lang.flag} {lang.code.toUpperCase()}
                    </button>
                  ))}
                </div>
              )}
            </div>
            {user && (
              <Link to="/profile" className="text-white hover:text-accent transition duration-300">
                <User size={20} />
              </Link>
            )}
            <button 
              className="text-white hover:text-accent transition duration-300 focus:outline-none" 
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            >
              {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
            </button>
          </div>
        </div>
      </div>
      {isMenuOpen && (
        <div className="md:hidden bg-primary-dark">
          {menuItems.map((item) => (
            <button
              key={item.to}
              onClick={() => handleNavClick(item.to)}
              className="flex items-center w-full py-2 px-4 text-sm text-white hover:bg-secondary hover:text-accent transition duration-300"
            >
              <item.icon size={20} className="mr-2" />
              <span>{t(item.text)}</span>
            </button>
          ))}
        </div>
      )}
      <AuthModal isOpen={isAuthModalOpen} onClose={() => setIsAuthModalOpen(false)} />
    </header>
  );
};

export default Header;