import React from 'react';
import { useAppContext } from '../../AppContext';
import useScrollToTop from '../../hooks/useScrollToTop';

const CookiePolicy = () => {
  const { t } = useAppContext();
  useScrollToTop();

  return (
    <div className="container mx-auto px-4 py-8 mt-16">
      <h1 className="text-3xl font-bold mb-6">{t("cookiePolicy")}</h1>
      
      <p className="mb-4">{t("cookiePolicyIntro")}</p>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">{t("whatAreCookies")}</h2>
        <p className="mb-4">{t("cookiesExplanation")}</p>
        <p className="mb-4">{t("firstThirdPartyCookies")}</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">{t("whyWeUseCookies")}</h2>
        <p className="mb-4">{t("cookiesUsageExplanation")}</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">{t("typesOfCookiesWeUse")}</h2>
        <p className="mb-4">{t("cookieTypesIntro")}</p>
        <ul className="list-disc pl-8 mb-4">
          <li><strong>{t("essentialCookies")}</strong> {t("essentialCookiesDesc")}</li>
          <li><strong>{t("performanceCookies")}</strong> {t("performanceCookiesDesc")}</li>
          <li><strong>{t("analyticsCookies")}</strong> {t("analyticsCookiesDesc")}</li>
          <li><strong>{t("advertisingCookies")}</strong> {t("advertisingCookiesDesc")}</li>
          <li><strong>{t("socialNetworkingCookies")}</strong> {t("socialNetworkingCookiesDesc")}</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">{t("howToControlCookies")}</h2>
        <p className="mb-4">{t("cookieControlExplanation")}</p>
        <p className="mb-4">{t("browserControlExplanation")}</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">{t("updatesPolicy")}</h2>
        <p className="mb-4">{t("policyUpdateExplanation")}</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">{t("moreInfo")}</h2>
        <p className="mb-4">{t("contactForMoreInfo")}</p>
        <p>
          Morgan Mathien<br />
          {t("address")}<br />
          {t("email")}: morganmathien@gmail.com
        </p>
      </section>

      <p className="text-sm text-gray-600">
        {t("lastUpdated")} {new Date().toLocaleDateString()}
      </p>
    </div>
  );
};

export default CookiePolicy;