// src/translations/en.js
export default {
  // Header
  "packages": "Packages",
  "services": "Services",
  "about": "About",
  "contact": "Contact",
  "letsTalk": "Let's Talk",

  // Hero
  "elevateYourBusinessGrowth": "Elevate Your Business Growth",
  "heroDescription": "Experience strategic business development with personalized solutions and expert guidance. Let's transform challenges into opportunities and drive your success.",
  "startYourJourney": "Start Your Journey",

  // Services
  "elevateYourBusinessWithExpertSolutions": "Elevate Your Business with Expert Solutions",
  "servicesDescription": "Hello, I'm Morgan, your dedicated business development consultant. With a wealth of global experience and a diverse skill set, I'm here to help your business thrive in today's competitive landscape. My services are designed to streamline your operations, uncover new opportunities, and drive sustainable growth.",
  "businessGrowthStrategy": "Business Growth Strategy",
  "businessGrowthStrategyDesc": "Develop tailored strategies to drive your business forward and achieve sustainable growth.",
  "customerServiceSupport": "Customer Service Support",
  "customerServiceSupportDesc": "Enhance your customer experience with expert support and relationship management.",
  "administrativeSupport": "Administrative Support",
  "administrativeSupportDesc": "Streamline your operations with efficient administrative and organizational solutions.",
  "internationalBusinessDevelopment": "International Business Development",
  "internationalBusinessDevelopmentDesc": "Leverage global expertise to expand your business across borders.",
  "salesDevelopment": "Sales Development",
  "salesDevelopmentDesc": "Implement effective sales strategies and processes to boost your revenue.",
  "marketResearch": "Market Research",
  "marketResearchDesc": "Gain valuable insights with comprehensive market research and analysis.",

  // About
  "aboutMorgan": "About me",
  "aboutDescription": "Hi there, I'm Morgan, and I'm passionate about driving business growth and fostering global impact. With a diverse background spanning travel management, event coordination, customer service, and business development, I've honed a unique skill set that allows me to navigate complex challenges while identifying opportunities for expansion and positive change.",
  "myMission": "My Mission",
  "missionDescription": "I partner with wellness brands and adventure companies to drive growth, foster global impact, and inspire positive change. By leveraging my expertise in business development, health, nutrition, and adventure, I craft tailored strategies that enhance brand visibility, build authentic partnerships, and promote holistic wellness.",
  "ourCoreValues": "Core Values",
  "resultsDriven": "Results-Driven",
  "resultsDrivenDesc": "We focus on achieving tangible, measurable outcomes for your business.",
  "innovativeSolutions": "Innovative Solutions",
  "innovativeSolutionsDesc": "We bring fresh, creative ideas to overcome your business challenges.",
  "clientCentricApproach": "Client-Centric Approach",
  "clientCentricApproachDesc": "Your success is at the heart of everything we do.",
  "globalPerspective": "Global Perspective",
  "globalPerspectiveDesc": "We leverage international experience to give your business a competitive edge.",
  "continuousImprovement": "Continuous Improvement",
  "continuousImprovementDesc": "We constantly evolve our strategies to stay ahead in the ever-changing business landscape.",
  "integrityTransparency": "Integrity & Transparency",
  "integrityTransparencyDesc": "We believe in honest, open communication and ethical business practices.",
  "wellnessFocus": "Wellness Focus",
  "wellnessFocusDesc": "We promote healthier lifestyles through our business strategies.",
  "adventureSpirit": "Adventure Spirit",
  "adventureSpiritDesc": "We infuse an adventurous mindset into business growth.",
  "letsCreateImpactTogether": "Let's Create Impact Together",
  "impactDescription": "Whether you're a wellness brand, an adventure company, or a business looking to make a global difference, let's embark on this journey together and create extraordinary growth and positive change!",

  // Packages
  "placesTitle": "Places I've Been",
  "ourServicePackages": "Our Service Packages",
  "chooseThePerfectPackage": "Choose the perfect package to accelerate your business growth",
  "hourlyConsultation": "Hourly Consultation",
  "hourlyConsultationDesc": "Flexible, on-demand business development advice and support.",
  "projectBasedSolutions": "Project-Based Solutions",
  "projectBasedSolutionsDesc": "Comprehensive support for specific business growth initiatives.",
  "retainerServices": "Retainer Services",
  "retainerServicesDesc": "Ongoing strategic partnership for continuous business improvement and growth.",
  "oneOnOneExpertConsultation": "One-on-one expert consultation",
  "tailoredAdvice": "Tailored advice for your business needs",
  "flexibleScheduling": "Flexible scheduling",
  "noLongTermCommitment": "No long-term commitment required",
  "inDepthBusinessAnalysis": "In-depth business analysis",
  "customStrategyDevelopment": "Custom strategy development",
  "implementationSupport": "Implementation support",
  "projectManagementAndReporting": "Project management and reporting",
  "dedicatedBusinessDevelopmentSupport": "Dedicated business development support",
  "regularStrategySessions": "Regular strategy sessions",
  "continuousImprovementInitiatives": "Continuous improvement initiatives",
  "priorityAccessToOurExpertise": "Priority access to our expertise",
  "startingAt25PerHour": "Starting at $25/hour",
  "customQuoteBasedOnProjectScope": "Custom quote based on project scope",
  "startingAt500PerMonth": "Starting at $500/month",
  "getStarted": "Get Started",

  // Testimonials
  "clientTestimonials": "Client Testimonials",
  "patrickTestimonial": "Working with Morgan has been a game-changer for my business. She's incredibly organized, proactive, and always brings fresh ideas to the table. Whether it's streamlining processes, managing client communication, or helping with strategy, Morgan handles everything with professionalism and ease. She's been a huge asset, and I can't recommend her enough!",
  "eileenTestimonial": "I'm extremely happy with Morgan's amazing family travel services. Her careful planning and talent for creating unforgettable experiences went above and beyond what we expected. Thanks to her, our trip became a truly special family memory.",
  "lesleeTestimonial": "Morgan has been an absolute lifesaver in helping me grow my sales. Her ability to organize, track leads, and follow up with potential clients has taken so much off my plate. She's always two steps ahead, making sure nothing falls through the cracks. Her insights and support have made a real impact on my business. I couldn't have scaled my sales efforts without her, and I'm so grateful for her help!",

  // FAQ
  "frequentlyAskedQuestions": "Frequently Asked Questions",
  "howCanYourServicesHelp": "How can your services help my business grow?",
  "howCanYourServicesHelpAnswer": "My services are designed to identify growth opportunities, streamline operations, and implement effective strategies. I'll work closely with you to understand your business goals and develop tailored solutions to achieve them, whether it's through market research, sales development, or operational efficiency improvements.",
  "whatTypesOfBusinesses": "What types of businesses do you work with?",
  "whatTypesOfBusinessesAnswer": "I work with a wide range of businesses, from startups to established companies, across various industries. My diverse experience allows me to adapt my approach to suit the unique needs of each client, regardless of their size or sector.",
  "howLongToSeeResults": "How long does it typically take to see results?",
  "howLongToSeeResultsAnswer": "The timeline for results can vary depending on the specific goals and challenges of your business. Some improvements, like operational efficiencies, can be seen relatively quickly. For larger strategic initiatives, it may take several months to see significant results. I'll work with you to set realistic expectations and milestones.",
  "howDoYouCharge": "How do you charge for your services?",
  "howDoYouChargeAnswer": "My pricing structure is flexible and depends on the scope and duration of the project. I offer both project-based and retainer options. We'll discuss your needs in detail and I'll provide a transparent quote tailored to your specific requirements.",

  // Contact
  "getInTouch": "Get in Touch",
  "name": "Name",
  "email": "Email",
  "message": "Message",
  "sendMessage": "Send Message",
  "sending": "Sending...",
  "thankYouMessage": "Thank you for your message. We'll get back to you soon!",
  "failedToSendMessage": "Failed to send message",
  "errorOccurred": "An error occurred while sending your message. Please try again.",

  // Footer
  "legal": "Legal",
  "privacyPolicy": "Privacy Policy",
  "termsOfUse": "Terms of Use",
  "cookiePolicy": "Cookie Policy",
  "contactInfo": "Contact Info",
  "copyrightNotice": "© 2024 Morgan Mathien - Delivering value. All rights reserved.",

  // Cookie Policy
  "cookiePolicyIntro": "This Cookie Policy explains how Morgan Mathien uses cookies and similar technologies to recognize you when you visit our website. It explains what these technologies are and why we use them, as well as your rights to control our use of them.",
  "whatAreCookies": "What are cookies?",
  "cookiesExplanation": "Cookies are small data files that are placed on your computer or mobile device when you visit a website. Cookies are widely used by website owners in order to make their websites work, or to work more efficiently, as well as to provide reporting information.",
  "firstThirdPartyCookies": "Cookies set by the website owner (in this case, Morgan Mathien) are called 'first party cookies'. Cookies set by parties other than the website owner are called 'third party cookies'. Third party cookies enable third party features or functionality to be provided on or through the website.",
  "whyWeUseCookies": "Why do we use cookies?",
  "cookiesUsageExplanation": "We use first party and third party cookies for several reasons. Some cookies are required for technical reasons in order for our Website to operate, and we refer to these as 'essential' or 'strictly necessary' cookies. Other cookies also enable us to track and target the interests of our users to enhance the experience on our Website. Third parties serve cookies through our Website for advertising, analytics and other purposes.",
  "typesOfCookiesWeUse": "Types of cookies we use",
  "cookieTypesIntro": "The specific types of first and third party cookies served through our Website and the purposes they perform are described below:",
  "essentialCookies": "Essential website cookies:",
  "essentialCookiesDesc": "These cookies are strictly necessary to provide you with services available through our Website and to use some of its features.",
  "performanceCookies": "Performance and functionality cookies:",
  "performanceCookiesDesc": "These cookies are used to enhance the performance and functionality of our Website but are non-essential to their use.",
  "analyticsCookies": "Analytics and customization cookies:",
  "analyticsCookiesDesc": "These cookies collect information that is used either in aggregate form to help us understand how our Website is being used or how effective our marketing campaigns are, or to help us customize our Website for you.",
  "advertisingCookies": "Advertising cookies:",
  "advertisingCookiesDesc": "These cookies are used to make advertising messages more relevant to you.",
  "socialNetworkingCookies": "Social networking cookies:",
  "socialNetworkingCookiesDesc": "These cookies are used to enable you to share pages and content that you find interesting on our Website through third party social networking and other websites.",
  "howToControlCookies": "How can you control cookies?",
  "cookieControlExplanation": "You have the right to decide whether to accept or reject cookies. You can exercise your cookie preferences by clicking on the appropriate opt-out links provided in the cookie banner.",
  "browserControlExplanation": "You may also set or amend your web browser controls to accept or refuse cookies.",
  "updatesPolicy": "How often will we update this Cookie Policy?",
  "policyUpdateExplanation": "We may update this Cookie Policy from time to time in order to reflect, for example, changes to the cookies we use or for other operational, legal or regulatory reasons.",
  "moreInfo": "Where can you get further information?",
  "contactForMoreInfo": "If you have any questions about our use of cookies or other technologies, please email us at:",

  // Terms of Use
  "acceptanceOfTerms": "1. Acceptance of Terms",
  "acceptanceTermsDesc": "By accessing and using this website, you agree to comply with and be bound by the following terms and conditions of use. If you do not agree with these terms, please refrain from using this website.",
  "intellectualProperty": "2. Intellectual Property",
  "intellectualPropertyDesc": "All content and materials available on this site, including but not limited to text, graphics, logos, images, and software, are the property of Morgan Mathien and are protected by applicable copyright and trademark laws.",
  "userConduct": "3. User Conduct",
  "userConductDesc1": "You agree not to engage in any conduct that may disrupt or interfere with the functionality or security of the website. Unauthorized use of this website may give rise to a claim for damages and/or be a criminal offense.",
  "userConductDesc2": "Additionally, you agree not to:",
  "conductRule1": "Use the website for any unlawful purpose or in violation of these terms",
  "conductRule2": "Attempt to gain unauthorized access to any portion of the website",
  "conductRule3": "Interfere with or disrupt the operation of the website or the servers or networks used to make the website available",
  "conductRule4": "Impersonate any person or entity, or falsely state or otherwise misrepresent your affiliation with a person or entity",
  "limitationOfLiability": "4. Limitation of Liability",
  "limitationLiabilityDesc1": "Morgan Mathien is not liable for any damages, direct or indirect, arising from the use of this website. This includes, but is not limited to, any loss of data or profit.",
  "limitationLiabilityDesc2": "We strive to provide accurate and up-to-date information, but we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability or availability with respect to the website or the information, products, services, or related graphics contained on the website for any purpose.",
  "modificationsToTerms": "5. Modifications to Terms",
  "modificationsTermsDesc": "Morgan Mathien reserves the right to revise these terms of use at any time without notice. By using this website, you are agreeing to be bound by the current version of these terms of service.",
  "governingLaw": "6. Governing Law",
  "governingLawDesc": "These terms and conditions are governed by and construed in accordance with the laws of the jurisdiction where Morgan Mathien is established and you irrevocably submit to the exclusive jurisdiction of the courts in that State or location.",
  "contactInformation": "7. Contact Information",
  "contactInfoDesc": "If you have any questions about these Terms of Use, please contact us at:",

  // Privacy Policy
  "privacyPolicyIntro": "At Morgan Mathien, we are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines our practices concerning the collection, use, and disclosure of your data when you use our website and services.",
  "informationCollection": "1. Information Collection",
  "informationCollectionDesc": "We collect personal information, such as names and email addresses, voluntarily submitted by users. We may also collect non-personal information, such as IP addresses and browser type.",
  "additionalInfoCollected": "Additionally, we may collect:",
  "contactInfo": "Contact information",
  "travelPreferences": "Travel preferences and history",
  "paymentInfo": "Payment information (processed securely through third-party payment processors)",
  "customerServiceInfo": "Information you provide in customer service interactions",
  "useOfInformation": "2. Use of Information",
  "useOfInfoDesc": "Personal information is used to provide users with requested information or services. Non-personal information is used for statistical analysis, to improve the content and functionality of the website.",
  "additionalUseOfInfo": "We may also use your information to:",
  "personalizeExperience": "Personalize your experience and provide tailored travel recommendations",
  "processTransactions": "Process transactions and send related information",
  "sendEmails": "Send periodic emails regarding your bookings, our services, or other information",
  "respondInquiries": "Respond to your inquiries, questions, and/or other requests",
  "improveWebsite": "Improve our website and services based on user feedback and behavior",

  "dataSecurity": "3. Data Security",
  "dataSecurityDesc": "We implement reasonable security measures to protect against unauthorized access to or unauthorized alteration, disclosure, or destruction of personal information.",
  "securityMeasures": "Our security measures include:",
  "encryption": "Use of encryption for sensitive data transmission",
  "securityAudits": "Regular security audits and vulnerability assessments",
  "restrictedAccess": "Restricted access to personal information to employees who need it to perform their job functions",
  "employeeTraining": "Employee training on privacy and security practices",

  "thirdPartyDisclosure": "4. Third-Party Disclosure",
  "thirdPartyDisclosureDesc": "We do not sell, trade, or otherwise transfer personal information to outside parties. This does not include trusted third parties who assist us in operating our website or conducting our business, provided they agree to keep this information confidential.",
  "infoSharing": "We may share information with:",
  "serviceProviders": "Service providers (e.g., hosting services, payment processors)",
  "travelPartners": "Travel partners necessary to fulfill your bookings",
  "legalAuthorities": "Legal authorities if required by law or to protect our rights",

  "cookies": "5. Cookies",
  "cookiesDesc": "We may use cookies to enhance user experience. Users may choose to set their web browsers to refuse cookies or alert them when cookies are being sent.",
  "cookiesUsage": "We use both session cookies (which expire once you close your browser) and persistent cookies (which stay on your device for a set period or until you delete them). They help us:",
  "rememberPreferences": "Remember your preferences and settings",
  "understandUsage": "Understand how you use our website",
  "improveServices": "Improve our services and user experience",
  "providePersonalizedContent": "Provide personalized content and recommendations",

  "gdprCompliance": "6. GDPR Compliance",
  "gdprComplianceDesc": "We adhere to the General Data Protection Regulation (GDPR) principles. Users in the European Union have the right to access, correct, and delete their personal information.",
  "gdprRights": "Additional rights under GDPR include:",
  "rightToRestrict": "The right to restrict processing of your personal data",
  "rightToDataPortability": "The right to data portability",
  "rightToObject": "The right to object to processing of your personal data",
  "rightToWithdrawConsent": "The right to withdraw consent at any time",

  "childrenPrivacy": "7. Children's Privacy",
  "childrenPrivacyDesc": "Our services are not intended for children under 13 years of age. We do not knowingly collect personal information from children under 13. If you are a parent or guardian and believe we may have collected information about a child, please contact us immediately.",

  "changesPrivacyPolicy": "8. Changes to This Privacy Policy",
  "changesPrivacyPolicyDesc": "We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the 'Last updated' date at the bottom of this page. You are advised to review this Privacy Policy periodically for any changes.",

  "contactUs": "9. Contact Us",
  "contactUsDesc": "If you have any questions about this Privacy Policy, please contact us at:",

  // Common
  "address": "123 Travel Street, Wanderlust City, 12345, United States",
  "phone": "Phone",
  "mobile": "Mobile",
  "lastUpdated": "Last updated:",

  // Travel Map
  "placesIveBeen": "Places I've Been",

  // Booking Page
  "bookYourMeeting": "Book Your Meeting",

  // Error Messages
  "pageNotFound": "Page Not Found",
  "pageNotFoundMessage": "Sorry, the page you are looking for does not exist.",
  "returnHome": "Return to Home",

  // Auth Modal
  "login": "Login",
  "signUp": "Sign Up",
  "password": "Password",
  "signInWithGoogle": "Sign in with Google",
  "alreadyHaveAccount": "Already have an account?",
  "dontHaveAccount": "Don't have an account?",

  // Profile
  "profile": "Profile",
  "editProfile": "Edit Profile",
  "saveChanges": "Save Changes",
  "logOut": "Log Out",

  // Misc
  "loading": "Loading...",
  "errorOccurred": "An error occurred. Please try again.",
  "successfullyUpdated": "Successfully updated.",
  "confirmAction": "Are you sure you want to perform this action?",
  "yes": "Yes",
  "no": "No",
  "cancel": "Cancel",
  "submit": "Submit",
  "readMore": "Read More",
  "showLess": "Show Less",
  "viewAll": "View All",
  "backToTop": "Back to Top",

    // LinkedIn Card
    "linkedinCardTitle": "Business Development Consultant | Adventurer | World Traveler",
    "linkedinCardLocation": "Bali, Indonesia",
    "linkedinCardDescription": "Passionate about driving business growth and fostering global impact. With a diverse background spanning travel management, event coordination, customer service, and business development, I've honed a unique skill set that allows me to navigate complex challenges while identifying opportunities for expansion and positive change.",
    "linkedinCardCompany": "Freelance Business Development Consultant",
    "linkedinCardEducation": "SUNY Buffalo State College",
    "viewLinkedInProfile": "View LinkedIn Profile",
  
};