import React from 'react';
import { Target, Lightbulb, Users, Globe, TrendingUp, Shield, Heart, Compass } from 'lucide-react';
import { useAppContext } from '../../AppContext';
import LinkedInCard from '../LinkedInCard';

const About = () => {
  const { t } = useAppContext();

  const values = [
    { 
      icon: Target, 
      title: t("resultsDriven"), 
      description: t("resultsDrivenDesc")
    },
    { 
      icon: Lightbulb, 
      title: t("innovativeSolutions"), 
      description: t("innovativeSolutionsDesc")
    },
    { 
      icon: Users, 
      title: t("clientCentricApproach"), 
      description: t("clientCentricApproachDesc")
    },
    { 
      icon: Globe, 
      title: t("globalPerspective"), 
      description: t("globalPerspectiveDesc")
    },
    { 
      icon: TrendingUp, 
      title: t("continuousImprovement"), 
      description: t("continuousImprovementDesc")
    },
    { 
      icon: Shield, 
      title: t("integrityTransparency"), 
      description: t("integrityTransparencyDesc")
    },
    { 
      icon: Heart, 
      title: t("wellnessFocus"), 
      description: t("wellnessFocusDesc")
    },
    { 
      icon: Compass, 
      title: t("adventureSpirit"), 
      description: t("adventureSpiritDesc")
    },
  ];

  return (
    <section id="about" className="py-20 bg-gradient-to-b from-gray-50 to-white">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-4xl font-bold text-center mb-16 text-primary">{t("aboutMorgan")}</h2>
        
        <div className="flex flex-col lg:flex-row items-center justify-between mb-20">
          <div className="lg:w-1/2 lg:pr-12 mb-8 lg:mb-0">
            <p className="text-lg leading-relaxed text-gray-700 mb-6">
              {t("aboutDescription")}
            </p>
          </div>
          <div className="lg:w-1/2 flex justify-center">
            <div className="relative">
              <div className="absolute inset-0 bg-primary rounded-full opacity-10 transform -rotate-6"></div>
              <img 
                src="/images/morganphoto.png" 
                alt="Morgan Mathien" 
                className="rounded-full w-64 h-64 object-cover shadow-xl transform rotate-3 transition-transform duration-300 hover:rotate-0"
              />
            </div>
          </div>
        </div>
        
        <div className="bg-white rounded-lg shadow-lg p-8 mb-20">
          <h3 className="text-2xl font-bold mb-4 text-primary">{t("myMission")}</h3>
          <p className="text-lg text-gray-700 mb-4">
            {t("missionDescription")}
          </p>
        </div>

        <div className="mb-20">
          <LinkedInCard />
        </div>
        
        <h3 className="text-3xl font-bold text-center mb-12 text-primary">{t("ourCoreValues")}</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {values.map((value, index) => (
            <div key={index} className="bg-white rounded-lg shadow-md p-6 flex flex-col items-center text-center transition-all duration-300 hover:shadow-xl hover:-translate-y-1">
              <div className="bg-primary bg-opacity-10 rounded-full p-4 mb-4">
                <value.icon size={32} className="text-primary" />
              </div>
              <h4 className="font-bold text-lg mb-2 text-gray-800">{value.title}</h4>
              <p className="text-sm text-gray-600">{value.description}</p>
            </div>
          ))}
        </div>
        
        <div className="mt-20 text-center">
          <h3 className="text-3xl font-bold mb-6 text-primary">{t("letsCreateImpactTogether")}</h3>
          <p className="text-xl mb-10 text-gray-700">
            {t("impactDescription")}
          </p>
          <a href="#contact" className="bg-primary text-white font-bold py-4 px-8 rounded-full hover:bg-primary-dark transition duration-300 text-lg shadow-lg hover:shadow-xl transform hover:-translate-y-1">
            {t("startYourJourney")}
          </a>
        </div>
      </div>
    </section>
  );
};

export default About;